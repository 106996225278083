import React, { FC } from 'react';
import { Row } from 'antd';
import styles from './styles.module.scss'

interface IQuotationMarksWrapper {
  children: React.ReactElement
}

export const QuotationMarksWrapper: FC<IQuotationMarksWrapper> = React.memo(({children}) => {
  return (
    <Row className={styles.quotation_marks}>
      <svg className={styles.left_quotation_mark} width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 8.10308L3.16265 0.375H5.73867L3.64724 8.10308H0Z" fill="#15A6FF"/>
        <path d="M6.58034 8.10308L8.67177 0.375H12.319L9.16912 8.10308H6.58034Z" fill="#15A6FF"/>
      </svg>
      {children}
      <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 8.10308L3.16265 0.375H5.73867L3.64724 8.10308H0Z" fill="#15A6FF"/>
        <path d="M6.58034 8.10308L8.67177 0.375H12.319L9.16912 8.10308H6.58034Z" fill="#15A6FF"/>
      </svg>
    </Row>
  )
})