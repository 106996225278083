import { useNavigate } from 'react-router-dom';
import { SXList, SXTypography } from 'scribexUI';
import styled, { CSSProp } from 'styled-components';
import React, { useCallback, useState } from 'react';
import { OutlineArrowDownIcon } from 'scribexUI/icons';

const {Text} = SXTypography;
export type BreadcrumbItemType = {
  label: string;
  path: string;
  id: string
  icon?: React.ReactElement
  menu?: BreadcrumbItemType[]
}

interface BreadcrumbProps {
  items: BreadcrumbItemType[];
}

const BreadcrumbContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
`;

const StyledBreadcrumbList = styled.div<Record<string, CSSProp & any>>`
  position: absolute;
  top: 20px;
  width: 100%;
  z-index: 9999;
  border-radius: 2px;
`

const BreadcrumbItem = styled.div`
  margin-right: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;

  &:hover {
    background-color: #EFF2FF;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const StyledSelectBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  background-color: transparent;
  width: 100%;
  z-index: 999;
`;

const Separator = styled.span`
  margin: 0 8px;
  color: #68686B;
`;

export const SXBreadcrumb: React.FC<BreadcrumbProps> = ({items}) => {
  const [isListVisible, setIsListVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const onClick = useCallback((value: string) => {
    setIsListVisible(false)
    navigate(value)
  }, [navigate]);

  const onBackdropClick = useCallback(() => {
    setIsListVisible(false)
  }, []);

  return (
    <>
      {isListVisible && <StyledSelectBackdrop onClick={onBackdropClick}/>}
      <BreadcrumbContainer>
        {items.map((item, index) => {
            return <React.Fragment key={item.id}>
              <BreadcrumbItem onClick={() => {
                item.menu?.length ? setIsListVisible(true) : onClick(item.path);
              }}>
                {item.icon}
                <Text size={'md'} color={items.length - 1 === index ? '#222222' : '#68686B'}>{item.label}</Text>
                {item.menu?.length && <OutlineArrowDownIcon/>}
                {item.menu?.length && isListVisible && <StyledBreadcrumbList>
                  <SXList list={item.menu} size={'fill'} onClick={(path) => {
                    setIsListVisible(false);
                    onClick(path);
                  }}/>
                </StyledBreadcrumbList>}
              </BreadcrumbItem>
              {index !== items.length - 1 && <Separator>/</Separator>}
            </React.Fragment>
          }
        )}
      </BreadcrumbContainer>
    </>
  );
};
