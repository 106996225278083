import React from 'react';
import { SXModal } from 'scribexUI';
import { ROUTES } from 'constants/routes';
import { Link, Outlet } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Col, Cookie, Layout, ManageCookie, NavIcon, NavIcon2, Row, ScribexLogo, ScribexMainNav } from 'componnets';
import { useFullWidthLayout } from './useFullWidthLayout';
import styles from './styles.module.scss';

const {credo, services, home, careers} = ROUTES;

export const FullWidthLayout = React.memo(() => {
  const {
    isActive,
    burgerMenuToggle,
    setIsActive,
    isModalOpen,
    onModalClose,
    onMoreClick,
    isManageModalOpen,
    onManageModalClose,
    onAllowAll,
    location
  } = useFullWidthLayout();

  const classNameMapping: Record<string, string> = {
    [credo]: 'credo',
    [services]: 'services',
    [careers]: 'careers'
  };

  return (
    <>
      <Layout>
        <Row
          className={`${styles.full_width_layout} ${styles[classNameMapping[location.pathname]]} ${isActive ? styles.nav_active : ""}`}
          // gutter={[0, {xs: 2, sm: 12}]}
        >
          <Col span={24} className={styles.header_section}
            // style={{backgroundColor: !isActive && location.pathname === '/credo' ? '#331DAD' : 'unset'}}
          >
            <Row className={styles.home_header} justify={'space-between'} align={'middle'}>
              <Link style={{zIndex: 1, height: '40px'}} to={home} onClick={() => setIsActive(false)}>
                <ScribexLogo width={131} height={40}/>
              </Link>
              <div
                onClick={burgerMenuToggle}
                className={`${styles.button} ${isActive ? styles.burger_active : ""}`}>
                <NavIcon2/>
                <NavIcon/>
                <NavIcon2/>
              </div>
            </Row>
          </Col>
          <Col span={24}>
            {isActive ? <AnimatePresence mode="wait">
              <ScribexMainNav onLinkClick={setIsActive}/>
            </AnimatePresence> : <Row className={styles.outlet}>
              <Outlet/>
            </Row>}
          </Col>
        </Row>
      </Layout>
      <SXModal
        size={'lg'}
        withActions={false}
        isOpen={isModalOpen}
        onClose={onModalClose}
        position={'flex-end'}
        roundness={'sharp'}
      >
        <Cookie onMoreClick={onMoreClick} onAllowAll={onAllowAll}/>
      </SXModal>
      <SXModal
        size={'fill'}
        withActions={false}
        isOpen={isManageModalOpen}
        onClose={onManageModalClose}
        roundness={'sharp'}
      >
        <ManageCookie onModalClose={onManageModalClose}/>
      </SXModal>
    </>
  )
})