import React from 'react';
import { SXTypography } from 'scribexUI';
import { Row, Col, Space } from 'componnets';
import { getColumnHeights } from 'utils/helpers';
// import { AnimatePresence, motion } from 'framer-motion';
import { useProjectForm } from './useProjectForm';
import styles from './styles.module.scss'
import { Form } from './form';

const {Text} = SXTypography;
const columnHeights = getColumnHeights(16);

export const ProjectForm = React.memo(() => {
  const {width} = useProjectForm();

  return (
    <Row className={styles.form_page_container}>
      <Col span={24} className={styles.title_section}>
        <Space size={width > 1024 ? 40 : 12} className={styles.title_container}
               direction={width > 1024 ? 'horizontal' : 'vertical'}>
          <Text className={styles.title}>
            GOT A PROJECT IN MIND?
          </Text>
          <Row className={styles.info_container}>
            <Col span={24}>
              <Text className={styles.info}>
                VISION, CONCEPT, PAIN POINT..? MAYBE A CHALLENGE?
              </Text>
            </Col>
            <Col>
              <Text className={styles.info}>
                WE LOVE CHALLENGES! LET US KNOW AND WE’LL GET BACK WITH QUESTIONS AND IDEAS.
              </Text>
            </Col>
          </Row>
        </Space>
      </Col>
      <Col span={24} className={styles.main_section}>
        <Row align={'middle'} className={styles.animation_form_container} wrap={width <= 1024}>
          <Col className={styles.left_banner} flex={width > 1024 ? '47.4%' : '100%'}>
            <Row className={styles.animation_container}>
              <Row className={styles.animation_content}>
                <Row className={styles.animation_content_text}>
                </Row>
              </Row>
              <Row className={styles.blur_column_container}>
                 {/*
                 <AnimatePresence>*/}
                   {columnHeights.map((_height, index) => {
                     const heightSize = width > 1024 ? _height : width > 450 ? (_height - _height * 25 / 100) : (_height - _height / 2);
                     return (<div
                       // initial={{y: 0, opacity: 1}}
                       style={{
                         width: `${100 / 16}%`,
                         height: `${heightSize}px`
                       }}
                      // animate={{
                      //   y: 0,
                      //   opacity: 1,
                      //  height:  [heightSize + 30, heightSize - 30, heightSize + 30]
                         // height: heightSize + 50
                      // }}
                      // exit={{y: 0, opacity: 1}}
                      // transition={{
                      //   duration: 2.5,
                      //   repeat: 10,
                      //   repeatType: "mirror",
                        // delay: index * 0.1,
                      // }}
                      key={Math.random().toString(16).slice(2) + Date.now()}
                      className={styles.blur_column}></div>)
                  })}
                {/*
                </AnimatePresence>*/}
              </Row>
            </Row>
          </Col>
          <Col flex={1} className={styles.right_banner}>
            <Form/>
          </Col>
        </Row>
      </Col>
    </Row>
  )
})