import { motion } from 'framer-motion';
import { SXTypography } from 'scribexUI';
import { ROUTES } from 'constants/routes';
import React, { FC, useState } from 'react';
import { Col, Row, Space } from 'componnets';
import { useLocation } from 'react-router-dom';
// import { FBICon, InstaIcon, TwitterIcon, WhatsAppIcon } from 'componnets';
import styles from './styles.module.scss';
import { NavLink } from './link';

const {Text} = SXTypography;
const {credo, contact, services, team, careers} = ROUTES;

const navItems = [
  {
    title: "CREDO",
    href: credo,
  },
  {
    title: "SERVICES",
    href: services,
  },
  {
    title: "TEAM",
    href: team,
  },
  {
    title: "CAREERS",
    href: careers
  },
  {
    title: "CONTACTS",
    href: contact,
  },
];

interface IScribexMainNav {
  onLinkClick: (value: boolean) => void
}

export const ScribexMainNav: FC<IScribexMainNav> = React.memo(({onLinkClick}) => {
  const location = useLocation();
  const [selectedIndicator, setSelectedIndicator] = useState(location.pathname);
  const container = {
    hidden: {
      opacity: 0
    },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
      }
    }
  }

  return (
    <Row className={styles.nav} justify={'space-between'} align={'bottom'} wrap={false}>
      <motion.div variants={container}
                  initial="hidden"
                  animate="show" className={styles.nav_container}>
        {
          navItems.map((data, index) => {
            return <NavLink
              onLinkClick={onLinkClick}
              key={data.title}
              data={{...data, index}}
              isActive={selectedIndicator == data.href}
              setSelectedIndicator={setSelectedIndicator}></NavLink>
          })
        }
      </motion.div>
      <Col>
        <Row gutter={[0, 10]}>
          <Col span={24}>
            {/*
            <Row justify={'end'} className={styles.contact_info} align={'middle'}>*/}
            {/*  <Space size={16} align={'center'}>*/}
            {/*    <WhatsAppIcon/>*/}
            {/*    <FBICon/>*/}
            {/*    <InstaIcon/>*/}
            {/*    <TwitterIcon/>*/}
            {/*  </Space>*/}
            {/*
            </Row>*/}
          </Col>
          <Col span={24}>
            <Row justify={'end'} className={styles.contact_info} align={'middle'}>
              <Space size={12} align={'center'}>
                <a href="mailto:info@scribex.io">
                  <Text color={'#FFFFFF'} className={styles.contact}>
                    info@scribex.io
                  </Text>
                </a>
                <a href="tel:+971 44 55 85 40">
                  <Text color={'#FFFFFF'} className={styles.contact}>
                    +971 44 55 85 40
                  </Text>
                </a>
              </Space>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
})