export const ErrorIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 8.60417C6.76083 8.60417 6.5625 8.40583 6.5625 8.16667V5.25C6.5625 5.01083 6.76083 4.8125 7 4.8125C7.23917 4.8125 7.4375 5.01083 7.4375 5.25V8.16667C7.4375 8.40583 7.23917 8.60417 7 8.60417Z"
        fill="#FF505A"/>
      <path
        d="M6.99935 10.5002C6.96435 10.5002 6.92352 10.4943 6.88268 10.4885C6.84768 10.4827 6.81268 10.471 6.77768 10.4535C6.74268 10.4418 6.70768 10.4243 6.67268 10.401C6.64352 10.3777 6.61435 10.3543 6.58518 10.331C6.48018 10.2202 6.41602 10.0685 6.41602 9.91682C6.41602 9.76516 6.48018 9.61349 6.58518 9.50266C6.61435 9.47932 6.64352 9.45599 6.67268 9.43266C6.70768 9.40932 6.74268 9.39182 6.77768 9.38016C6.81268 9.36266 6.84768 9.35099 6.88268 9.34516C6.95852 9.32766 7.04018 9.32766 7.11018 9.34516C7.15102 9.35099 7.18602 9.36266 7.22102 9.38016C7.25602 9.39182 7.29102 9.40932 7.32602 9.43266C7.35518 9.45599 7.38435 9.47932 7.41352 9.50266C7.51852 9.61349 7.58268 9.76516 7.58268 9.91682C7.58268 10.0685 7.51852 10.2202 7.41352 10.331C7.38435 10.3543 7.35518 10.3777 7.32602 10.401C7.29102 10.4243 7.25602 10.4418 7.22102 10.4535C7.18602 10.471 7.15102 10.4827 7.11018 10.4885C7.07518 10.4943 7.03435 10.5002 6.99935 10.5002Z"
        fill="#FF505A"/>
      <path
        d="M10.5351 12.9266H3.46511C2.32761 12.9266 1.45844 12.5124 1.01511 11.7657C0.577608 11.0191 0.635941 10.0566 1.19011 9.05907L4.72511 2.70074C5.30844 1.65074 6.11344 1.07324 7.00011 1.07324C7.88677 1.07324 8.69177 1.65074 9.27511 2.70074L12.8101 9.06491C13.3643 10.0624 13.4284 11.0191 12.9851 11.7716C12.5418 12.5124 11.6726 12.9266 10.5351 12.9266ZM7.00011 1.94824C6.45177 1.94824 5.91511 2.36824 5.48927 3.12658L1.96011 9.49074C1.56344 10.2024 1.49927 10.8557 1.77344 11.3282C2.04761 11.8007 2.65427 12.0574 3.47094 12.0574H10.5409C11.3576 12.0574 11.9584 11.8007 12.2384 11.3282C12.5184 10.8557 12.4484 10.2082 12.0518 9.49074L8.51094 3.12658C8.08511 2.36824 7.54844 1.94824 7.00011 1.94824Z"
        fill="#FF505A"/>
    </svg>
  );
};
