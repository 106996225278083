export const NavIcon2 = () => {
  return (

    <svg width="26" height="3" viewBox="0 0 26 3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 2.33398H1C0.453333 2.33398 0 1.88065 0 1.33398C0 0.787318 0.453333 0.333984 1 0.333984H25C25.5467 0.333984 26 0.787318 26 1.33398C26 1.88065 25.5467 2.33398 25 2.33398Z"
        fill="#140D0B"/>
    </svg>


  )
}