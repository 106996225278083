import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { SXTypography } from 'scribexUI';
import { OptionListType } from 'typings';
import { MinusIcon, PlusIcon } from 'componnets';
import { useCollapse } from './useCollapse';
import styles from './styles.module.scss';

const {Text} = SXTypography;

export const SXCollapse: FC<OptionListType> = React.memo((props) => {
  const {label, info, id} = props;
  const {isCollapsed, onClick} = useCollapse(id);

  return (
    <Row className={styles.collapse} gutter={[0, 12]}>
      <Col span={24}>
        <Row justify={'space-between'} align={'middle'} className={styles.label_container} onClick={() => onClick(id)} wrap={false}>
          <Col>
            <Text size={'lg'} weight={'medium'} color={'#140D0B'} className={styles.label}>{label}</Text>
          </Col>
          {isCollapsed && <PlusIcon/>}
          {!isCollapsed && <MinusIcon/>}
        </Row>
      </Col>
      {!isCollapsed && <Col>
        <Text color={'#68686B'} className={styles.info}>{info}</Text>
      </Col>}
    </Row>
  )
})