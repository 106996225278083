import { api } from 'api';
import { ROUTES } from 'constants/routes';
import { makeNiceName } from 'utils/helpers';
import { ErrorMappingValueType } from 'typings';
import { Form, FormProps, notification } from 'antd';
import { ValidationInfoType } from 'scribexUI/typings';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type FieldType = {
  [key: string]: string
};

interface IValidationInfo {
  [key: string]: ValidationInfoType
}

const emailRegexp = new RegExp(/^[\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,}$/);
export const usePositionApplyForm = () => {
  const [form] = Form.useForm();
  const [file, setFile] = useState<File | null>(null);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [coverLatterCount, setCoverLatterCount] = useState(0);
  const [dynamicValidationMessage, setDynamicValidationMessage] = useState<IValidationInfo>({});

  const [formTemplate, {isLoading}] = api.useFormTemplateMutation();
  const [notificationApi, contextHolder] = notification.useNotification();
  const {state} = useLocation();
  const navigate = useNavigate();
  const {data = []} = api.useJobListQuery();

  const positionDetails: Record<string, any> = useMemo(() => {
    return data.find((_detail: any) => _detail.id === state.id) || {};
  }, [data, state.id]);

  const isRequiredCheck = useCallback(() => {
    let isRequiredCheckPass = true;
    Object.keys(positionDetails.formList).forEach((key) => {
      const formValues = form.getFieldsValue();
      if (positionDetails.formList[key]) {
        if (!formValues[key]) {
          setDynamicValidationMessage(prevState => ({
            ...prevState,
            [key]: {
              error: `${makeNiceName(key.toLowerCase())} is required!`
            }
          }))
          isRequiredCheckPass = false;
        } else {
          setDynamicValidationMessage(prevState => Object.fromEntries(
            Object.entries(prevState).filter(([existingKey]) => existingKey !== key)))
        }
      }
    });
    return isRequiredCheckPass;
  }, [positionDetails, form]);

  const validateFields = useCallback((formFields: FieldType) => {
    const isRequiredCheckPass = isRequiredCheck();
    const emailValidation = emailRegexp.test(formFields.EMAIL);
    setEmailIsValid(emailValidation);
    return emailValidation && isRequiredCheckPass;
  }, [isRequiredCheck]);

  const openNotificationWithIcon = useCallback((messageContent: ErrorMappingValueType) => {
    const {type, message} = messageContent;
    notificationApi[type]({
      message: message,
    });
  }, [notificationApi]);

  const onInputBlur = useCallback(({value, type}: Record<string, string>) => {
    if (type === 'EMAIL') {
      setEmailIsValid(emailRegexp.test(value));
    } else if (type === 'COVER_LATTER') {
      setCoverLatterCount(value.length);
    }
  }, []);

  const onFinish: FormProps<FieldType>['onFinish'] = useCallback((values: FieldType) => {
    const isValid = validateFields(values);
    const formData = new FormData();
    if (isValid) {
      const payload: Record<string, any> = {
        name: values.NAME || null,
        email: values.EMAIL || null,
        coverLatter: values.COVER_LATTER || null,
        file: values.FILE || null,
      }
      Object.keys(payload).forEach((key) => {
        if (key === 'file' && payload[key]) {
          formData.append("file", payload[key], payload[key]?.name);
        } else {
          formData.append(key, payload[key]);
        }
      });
      formData.append('jobId', state.id);
      formTemplate(formData)
        .then(({data, error}: any) => {
          if (data?.status && data?.status === "ok") {
            navigate(ROUTES.formSubmitted);
          } else {
            if (data) {
              Object.values(data).forEach((_message: any) => {
                _message?.length && openNotificationWithIcon({type: 'error', message: _message[0]});
              })
            } else if (error?.data) {
              error?.data?.message && openNotificationWithIcon({type: 'error', message: error.data.message});
            }
          }
        })
        .catch(err => {
          console.log(err, 'error')
        })
    }
  }, [formTemplate, validateFields, navigate, file, openNotificationWithIcon, state.id]);
  const handleFileChange = (selectedFile: File | null) => {
    form.setFieldValue('FILE', selectedFile);
    setFile(selectedFile);
  };

  return {
    form,
    file,
    contextHolder,
    emailIsValid,
    isLoading,
    dynamicValidationMessage,
    handleFileChange,
    onFinish,
    onInputBlur,
    coverLatterCount
  }
}