export const ErrorIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2413_9457)">
        <path
          d="M8.03221 4.15742C8.03221 4.10586 7.99002 4.06367 7.93846 4.06367L7.16503 4.06719L6.00019 5.45586L4.83652 4.06836L4.06191 4.06485C4.01035 4.06485 3.96816 4.10586 3.96816 4.1586C3.96816 4.18086 3.97637 4.20196 3.99043 4.21953L5.51503 6.03594L3.99043 7.85118C3.97627 7.86835 3.96841 7.88985 3.96816 7.91211C3.96816 7.96368 4.01035 8.00586 4.06191 8.00586L4.83652 8.00235L6.00019 6.61368L7.16385 8.00118L7.93729 8.00469C7.98885 8.00469 8.03104 7.96368 8.03104 7.91094C8.03104 7.88868 8.02284 7.86758 8.00877 7.85L6.48651 6.03477L8.01112 4.21836C8.02518 4.20196 8.03221 4.17969 8.03221 4.15742Z"
          fill="#F5222D"/>
        <path
          d="M6 0.761719C3.10078 0.761719 0.75 3.1125 0.75 6.01172C0.75 8.91094 3.10078 11.2617 6 11.2617C8.89922 11.2617 11.25 8.91094 11.25 6.01172C11.25 3.1125 8.89922 0.761719 6 0.761719ZM6 10.3711C3.59297 10.3711 1.64062 8.41875 1.64062 6.01172C1.64062 3.60469 3.59297 1.65234 6 1.65234C8.40703 1.65234 10.3594 3.60469 10.3594 6.01172C10.3594 8.41875 8.40703 10.3711 6 10.3711Z"
          fill="#F5222D"/>
      </g>
      <defs>
        <clipPath id="clip0_2413_9457">
          <rect width="12" height="12" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}