import React from 'react';
import { Col, Image, Row } from 'antd';
import { SXTypography } from 'scribexUI';
import img from 'assets/images/credo-img.png';

const {Text} = SXTypography;
import styles from './styles.module.scss';

export const Credo = React.memo(() => {
  return (
    <Col className={styles.credo} span={24}>
      <Col className={styles.img_container}>
        <Image src={img} preview={false} alt={'Image'}/>
      </Col>
      <Row align={'middle'} style={{height: '100%'}}>
        <Col flex={'951px'} lg={24} >
          <Col>
            <Text className={styles.title}>WE PRACTICE:</Text>
          </Col>
          <Col>
            <Text className={styles.description}>
              SIMPLICITY <Text italic className={styles.secondary_text}>AND</Text> PRECISION,
              MATHEMATICAL RIGOUR,
              COMPASSION <Text italic className={styles.secondary_text}>THROUGH</Text> USER-CENTRICITY,
              SHARING <Text italic className={styles.secondary_text}>AS AN</Text> INTELLECTUAL MULTIPLIER
            </Text>
          </Col>
        </Col>
      </Row>
    </Col>
  )
})