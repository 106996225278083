import { viewportSize } from 'utils/helpers';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

export const useHome = () => {
  const {width} = viewportSize();
  const navigate = useNavigate();
  let blurItemCount = 20;
  if (width < 769) {
    blurItemCount = Math.floor(width / 58);
  }

  const onProjectFormClick = useCallback(() => {
    navigate(ROUTES.projectForm)
  }, [navigate]);

  return {
    blurItemCount,
    onProjectFormClick
  }
}