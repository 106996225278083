import React, { FC } from 'react';
import styled from 'styled-components';

export type TitleType = {
  $level: 1 | 2 | 3 | 4 | 5;
}

const sizes: Record<TitleType["$level"], any> = {
  1: {
    fontSize: '40px',
    lineHeight: '48px'
  },
  2: {
    fontSize: '32px',
    lineHeight: '40px'
  },
  3: {
    fontSize: '24px',
    lineHeight: '32px'
  },
  4: {
    fontSize: '20px',
    lineHeight: '26px'
  },
  5: {
    fontSize: '16px',
    lineHeight: '24px'
  },
}

export interface TitleProps {
  level?: 1 | 2 | 3 | 4 | 5;
  children: React.ReactNode;
  className?:string,
}

const StyledTitle = styled.div<TitleType>`
  color: #222222;
  margin: 0;
  font-family: DMSans-ExtraBold, sans-serif;
  ${(props) => sizes[props.$level]}
`;

export const Title: FC<TitleProps> = (props) => {
  const {level = 1, children, className} = props;
  const Tag = `h${level}`;
  return (
    <StyledTitle
      className={className}
      $level={level}
      as={Tag}>{children}</StyledTitle>
  );
};
