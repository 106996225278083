import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Result } from 'componnets';
import { ROUTES } from 'constants/routes';
import { SXButton } from 'scribexUI';

export const NotAuthorized = React.memo(() => {
  const navigate = useNavigate();
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <SXButton type="primary" onClick={() => navigate(ROUTES.home)}>
          Back Home
        </SXButton>
      }
    />
  );

})