import React from 'react';
import { SXTypography, SXTextButton } from 'scribexUI'
import { Row, Col, Typography, HomeArrowIcon, Space } from 'componnets';
import styles from './styles.module.scss';
import { useHome } from './useHome';

const {Text} = Typography;
const {Text: SXText} = SXTypography;

export const Home = React.memo(() => {
  const {blurItemCount, onProjectFormClick} = useHome();

  return (
    <Col span={24}>
      <Row gutter={[0, {xs: 2, sm: 12}]} className={styles.home}>
        <Col span={24} className={styles.home_title_section}>
          <Text className={styles.home_title}>
            SCRIBEX
          </Text>
        </Col>
        <Col span={24} className={styles.blur_section}>
          <Row gutter={[0, {xs: 12, sm: 12, lg: 10}]}>
            <SXText weight={'strong'} color={'#140D0B'} className={styles.blur_description}>
              TRIBE OF DIGITAL SCRIBES CAMPED IN DUBAI, UAE
            </SXText>
            <Col span={24} className={styles.blur_content}>
              <div className={styles.blur_square}>
              </div>
              <div className={styles.blur_content_box}>
                <div className={styles.content_text}>
                  CODE PERFECT
                </div>
                {[...Array(blurItemCount)].map(() => <div
                  style={{width: `${100 / blurItemCount}%`}}
                  key={Math.random().toString(16).slice(2) + Date.now()}
                  className={styles.blur}></div>)}
              </div>
            </Col>
            <Col span={24} className={styles.blur_contact}>
              <Row justify={'space-between'} align={'middle'} gutter={[0, 6]}>
                <SXTextButton
                  onClick={onProjectFormClick}
                  color={'#140D0B'}
                  $right_icon={<HomeArrowIcon/>}>
                  GOT A PROJECT IN MIND?
                </SXTextButton>
                <Col>
                  <Space size={16} align={'center'}>
                    <a href="mailto:info@scribex.io">
                      <SXText color={'#140D0B'} className={styles.contact}>
                        info@scribex.io
                      </SXText>
                    </a>
                    <a href="tel:+971 44 55 85 40">
                      <SXText color={'#140D0B'} className={styles.contact}>
                        +971 44 55 85 40
                      </SXText>
                    </a>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  )
})