import React, { useCallback, useState } from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { LockIcon } from '../../icons';

type ToggleSizeType = 'sm' | 'md';

interface IToggle {
  defaultChecked?: boolean;
  onChange?: (value?: any) => void;
  size?: ToggleSizeType;
  disabled?: boolean;
  alwaysChecked?: boolean
}

interface IToggleTrack {
  size: ToggleSizeType
  $isActive: boolean;
  disabled: boolean
}

interface IToggleContainer {
  disabled: boolean;
}

interface IToggleSwitch {
  $isActive: boolean;
  size: ToggleSizeType
}

const toggleTrackSizes: Record<ToggleSizeType, CSSProp> = {
  sm: {
    width: '30px',
    height: '18px'
  },
  md: {
    width: '42px',
    height: '24px'
  }
};

const toggleSwitchSizes: Record<ToggleSizeType, CSSProp> = {
  sm: {
    width: '14px',
    height: '14px'
  },
  md: {
    width: '20px',
    height: '20px'
  }
};

const toggleSwitchLeft: Record<ToggleSizeType, CSSProp> = {
  sm: "14px",
  md: "20px"
};

const ToggleContainer = styled.label<IToggleContainer>`
  display: block;
  cursor: pointer;
  border: none;
  background: transparent;
  ${({disabled}) => disabled && css`cursor: not-allowed`};
`;

const ToggleTrack = styled.div<IToggleTrack>`
  border-radius: 40px;
  position: relative;

  ${({size}) => toggleTrackSizes[size]};
  ${({$isActive, disabled}) => $isActive ? css`
    background-color: ${disabled ? '#BBC1FF' : '#15A6FF'};

    &:hover:not([disabled]) {
      background-color: #4555FF;

      .lock-icon {
        fill: #4555FF;
      }
    }
  ` : css`
    background-color: ${disabled ? '#F2F2F2' : '#E6E6E6'};

    &:hover:not([disabled]) {
      background-color: #D5D5D5;
    }
  `}
`;

const StyledInput = styled.input`
  display: none;
`;
const ToggleSwitch = styled.div<IToggleSwitch>`
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({$isActive, size}) => ($isActive ? toggleSwitchLeft[size] : '2px')};
  ${({size}) => toggleSwitchSizes[size]};
  transition: left 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SXToggle: React.FC<IToggle> = (props) => {
  const {
    defaultChecked = false,
    onChange,
    size = 'sm',
    disabled = false,
    alwaysChecked = false
  } = props;
  const [toggleValue, setToggleValue] = useState<boolean>(alwaysChecked || defaultChecked);


  const handleClick = useCallback(() => {
    if (!alwaysChecked) {
      setToggleValue(prevState => !prevState);
      if (onChange) {
        onChange();
      }
    }
  }, [onChange, alwaysChecked]);
  return (
    <ToggleContainer disabled={disabled}>
      <StyledInput type='checkbox' checked={toggleValue} onChange={() => !disabled && handleClick()} />
      <ToggleTrack size={size} disabled={disabled} $isActive={toggleValue}>
        <ToggleSwitch $isActive={toggleValue} size={size}>
          {alwaysChecked && <LockIcon/>}
        </ToggleSwitch>
      </ToggleTrack>
    </ToggleContainer>
  );
};