import styled from 'styled-components';
import React, { FC } from 'react';

const StyledSelectBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  background-color: transparent;
  width: 100%;
  z-index: 999;
`;

interface ISXBackdrop {
  onBackdropClick?:() => void
}

export const SXBackdrop:FC<ISXBackdrop> = React.memo((props) => {
  const {onBackdropClick} = props;
  return <StyledSelectBackdrop onClick={onBackdropClick}/>
})