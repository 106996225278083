import React, { useEffect, useRef } from 'react';
import { SXTypography } from 'scribexUI';
import { FC, useCallback, useState } from 'react';
import { OutlineArrowDownIcon, OutlineArrowUpIcon } from 'scribexUI/icons';
import styled, { css, CSSProp } from 'styled-components';
import { OptionsSelectList, OptionType } from './SelectList';

const {Text} = SXTypography;

type SelectTypeSizeType = "sm" | 'md' | 'fill' | 'xs';

export type DefaultOptionType = {
  value: string,
  label: string
}

interface ISXSelect {
  disabled?: boolean
  size?: SelectTypeSizeType
  placeholder?: string
  onSelect?: (value: string | number) => void
  options: OptionType[]
  defaultOption?: DefaultOptionType
}

interface IStyledSelectWrapper {
  disabled: boolean
  $size: SelectTypeSizeType
}

interface IStyledIconWrapper {
  disabled: boolean;
  $size: SelectTypeSizeType
}

const sizes: Record<SelectTypeSizeType, CSSProp> = {
  sm: {
    maxWidth: '200px',
    maxHeight: '196px',
  },
  md: {
    maxWidth: '300px',
    maxHeight: '304px',
  },
  xs: {
    maxWidth: '90px',
    maxHeight: '304px',
  },
  fill: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}

const StyledSelectBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  background-color: transparent;
  width: 100%;
  z-index: 99;
`;

const StyledSelectWrapper = styled.div<IStyledSelectWrapper>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 4px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #D5D5D5;
  padding: 8px 16px;
  ${({$size}) => $size === 'xs' && css`
    padding: 5px 7px;
    gap: 2px;
  `}
  z-index: 999;
  ${({$size}) => sizes[$size]}
  ${(props) =>
          props.disabled && css<IStyledSelectWrapper>`
            border-color: #68686B;
            color: #68686B;
            cursor: not-allowed;
          `
  }
`;
const StyledIconWrapper = styled.span<IStyledIconWrapper>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  ${({$size}) => $size === 'xs' && css`
    width: 16px;
    height: 16px;
  `};
  ${({disabled}) => disabled && css<IStyledIconWrapper>`
    cursor: not-allowed;
  `}
`;

type StyledOptionsType = {
  $isBottom: boolean;
  $size: SelectTypeSizeType
}


const StyledOptions = styled.div<StyledOptionsType>`
  position: absolute;
  ${({$isBottom}) => $isBottom ? 'top: calc(100% + 2px)' : 'bottom: calc(100% + 2px)'};
  left: 0;
  border-radius: 6px;
  background-color: #FBFBFB;
  box-shadow: 0px 4px 8px 0px #00000014;
  ${({$size}) => sizes[$size]} //max-height: 400px;
  overflow-x: auto;
  z-index: 99999;
  width: 100%;
`;

export const SXSelect: FC<ISXSelect> = React.memo((props) => {
  const {
    defaultOption,
    disabled = false,
    size = 'md',
    placeholder ,
    onSelect,
    options,
  } = props;
  const [label, setLabel] = useState<string>(defaultOption?.label || placeholder || '');
  const [selectedValue, setSelectedValue] = useState<string | number | null>(defaultOption?.value || null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isBottom, setIsBottom] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultOption?.label) {
      setLabel(defaultOption.label);
      setSelectedValue(defaultOption.value);
    }
  }, [defaultOption]);

  const onOptionSelect = useCallback((event: React.MouseEvent, {value, label}: OptionType) => {
    event.stopPropagation();
    setIsOpen(false);
    setLabel(label);
    setSelectedValue(value);
    onSelect && onSelect(value);
  }, [onSelect]);

  const onSelectClick = useCallback(() => {
    if(!disabled) {
      if (selectRef.current) {
        const dropdownRect = selectRef.current.getBoundingClientRect();
        setIsBottom(window.innerHeight - dropdownRect.top > 400 || dropdownRect.bottom < 400);
      }
      setIsOpen(prevState => !prevState);
    }
  }, [disabled]);

  return (
    <>
      {isOpen && <StyledSelectBackdrop onClick={() => setIsOpen(false)}/>}
      <StyledSelectWrapper disabled={disabled} $size={size} onClick={onSelectClick} ref={selectRef}>
        <Text size={'md'} color={'#68686B'}>{label}</Text>
        <StyledIconWrapper $size={size} disabled={disabled}>
          {isOpen ? <OutlineArrowUpIcon/> : <OutlineArrowDownIcon/>}
        </StyledIconWrapper>
        {isOpen && <StyledOptions $size={size} $isBottom={isBottom}>
          <OptionsSelectList
            size={size}
            options={options}
            onOptionSelect={onOptionSelect}
            placeholder={placeholder}
            selectedValue={selectedValue}/>
        </StyledOptions>}
      </StyledSelectWrapper>
    </>
  )
})