import { useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';


const allCookieNames = ["performance", "functionality", "advertising", "third-party", "essential"];

export const useFullWidthLayout = () => {
  const [cookie, setCookie] = useCookies();
  const isCookieSet = allCookieNames.some(_cookie => cookie[_cookie]);

  const [isActive, setIsActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(!isCookieSet);
  const [isManageModalOpen, setIsManageModalOpen] = useState<boolean>(false);
  const location = useLocation()


  const burgerMenuToggle = useCallback(() => {
    setIsActive(prevState => !prevState);
  }, []);

  const onModalClose = useCallback(() => {
    setCookie("essential", true, {path: '/'});
    setIsModalOpen(false);
  }, [setCookie]);

  const onManageModalClose = useCallback(() => {
    setIsManageModalOpen(false);
    setCookie("essential", true, {path: '/'});
  }, [setCookie]);

  const onMoreClick = useCallback(() => {
    setIsManageModalOpen(true);
    setIsModalOpen(false);
  }, []);

  const onAllowAll = useCallback(() => {
    allCookieNames.forEach(_cookie => setCookie(_cookie, true, {path: '/'}));
    setIsModalOpen(false);
  }, [setCookie]);

  return {
    isActive,
    isModalOpen,
    isManageModalOpen,
    setIsActive,
    burgerMenuToggle,
    onModalClose,
    onManageModalClose,
    onMoreClick,
    onAllowAll,
    location
  }
}