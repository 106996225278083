export const ROUTES = {
  home: '/',
  team: '/team',
  credo: '/credo',
  careers: '/careers',
  contact: '/contacts',
  services: '/services',
  projectForm: '/project-form',
  positionDetails: '/position-details',
  notAuthorized: '/not-authorized',
  formSubmitted: '/project-form-submitted',
}