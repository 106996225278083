import React from 'react';
import { SXTypography } from 'scribexUI';
import styled, { css } from 'styled-components';
import { CircleDoneIcon, CloseIcon, ErrorIcon, PlusIcon, WarningIcon } from 'scribexUI/icons';

const {Text} = SXTypography;

type TagStatusType = 'active' | 'success' | 'error' | 'warning' | 'info' | 'disable';
type TagStateType = 'default' | 'add' | 'base';
type StyledTagType = {
  $roundness: 'circle' | 'square';
  $bordered: boolean;
  $state: TagStateType;
  $status: TagStatusType | undefined
}

interface TagProps {
  roundness?: StyledTagType['$roundness'];
  status?: TagStatusType;
  state?: TagStateType;
  children: string;
  type?: 'remove' | 'add' | 'status';
  bordered?: boolean,
  onClick?:() => void
  // TODO add custom icon
}

const TagStatus: Record<TagStatusType, any> = {
  active: {
    icon: <CircleDoneIcon color={'#5865F2'}/>,
    backgroundColor: '#EFF2FF',
    color: '#5865F2',
  },
  success: {
    icon: <CircleDoneIcon color={'#389E0D'}/>,
    backgroundColor: '#EFFFE0',
    color: '#389E0D',
  },
  error: {
    icon: <ErrorIcon/>,
    backgroundColor: '#FFF1F0',
    color: '#CF1322',
  },
  warning: {
    icon: <WarningIcon/>,
    backgroundColor: '#FFEDD8',
    color: '#F79009',
  },
  info: {
    icon: <CircleDoneIcon color={'#0B5CD5'}/>,
    backgroundColor: '#E6F0FF',
    color: '#0B5CD5',
  },
  disable: {
    icon: <CircleDoneIcon color={'#68686B'}/>,
    backgroundColor: '#F8F8F8',
    color: '#68686B',
  }
}

const state: Record<TagStateType, any> = {
  base: {
    borderColor: '#5865F2',
    backgroundColor: '#EFF2FF'
  },
  default: {
    backgroundColor: '#F8F8F8',
    borderColor: '#E6E6E6'
  },
  add: {
    border: '1px dashed #A9A9AA',
    backgroundColor: '#ffffff'
  }
}

const TagContainer = styled.div<StyledTagType>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  background-color: #EFF2FF;
  color: #5865F2;
  border-radius: 360px;
  gap: 4px;

  ${(props) => props.$roundness === 'square' && css`
    border-radius: 2px;
  `}

  ${(props) => props.$bordered && css`
    border: 1px solid #5865F2;
  `}
  ${(props) => state[props.$state]}

  ${(props) => props.$status && css`
    border: none;
    ${TagStatus[props.$status]}
  `}
`;

const IconWrapper = styled.span`
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const SXTag: React.FC<TagProps> = React.memo((props) => {
  const {
    children,
    type,
    bordered = false,
    state = 'base',
    status,
    roundness = 'circle',
    onClick
  } = props;
  const textColor = status ? TagStatus[status].color : '#5865F2';
  return (
    <TagContainer
      $state={state}
      $roundness={roundness}
      $bordered={bordered}
      $status={status}
    >
      {status && <IconWrapper>
        {TagStatus[status].icon}
      </IconWrapper>}
      {!status && type === "add" && <IconWrapper>
        <PlusIcon/>
      </IconWrapper>}
      <Text color={textColor} size={'sm'}>{children}</Text>
      {!status && type === "remove" && <IconWrapper onClick={onClick}>
        <CloseIcon/>
      </IconWrapper>}
    </TagContainer>
  );
});