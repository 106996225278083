export const InfoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M7.00033 13.2707C3.54116 13.2707 0.729492 10.459 0.729492 6.99984C0.729492 3.54067 3.54116 0.729004 7.00033 0.729004C10.4595 0.729004 13.2712 3.54067 13.2712 6.99984C13.2712 10.459 10.4595 13.2707 7.00033 13.2707ZM7.00033 1.604C4.02533 1.604 1.60449 4.02484 1.60449 6.99984C1.60449 9.97484 4.02533 12.3957 7.00033 12.3957C9.97533 12.3957 12.3962 9.97484 12.3962 6.99984C12.3962 4.02484 9.97533 1.604 7.00033 1.604Z"
        fill="#68686B"/>
      <path
        d="M7 8.02091C6.76083 8.02091 6.5625 7.82258 6.5625 7.58341V4.66675C6.5625 4.42758 6.76083 4.22925 7 4.22925C7.23917 4.22925 7.4375 4.42758 7.4375 4.66675V7.58341C7.4375 7.82258 7.23917 8.02091 7 8.02091Z"
        fill="#68686B"/>
      <path
        d="M6.99935 9.91668C6.92352 9.91668 6.84768 9.89918 6.77768 9.87001C6.70768 9.84085 6.64352 9.80001 6.58518 9.74751C6.53268 9.68918 6.49185 9.63085 6.46268 9.55501C6.43352 9.48501 6.41602 9.40918 6.41602 9.33335C6.41602 9.25751 6.43352 9.18168 6.46268 9.11168C6.49185 9.04168 6.53268 8.97751 6.58518 8.91918C6.64352 8.86668 6.70768 8.82585 6.77768 8.79668C6.91768 8.73835 7.08102 8.73835 7.22102 8.79668C7.29102 8.82585 7.35518 8.86668 7.41352 8.91918C7.46602 8.97751 7.50685 9.04168 7.53602 9.11168C7.56518 9.18168 7.58268 9.25751 7.58268 9.33335C7.58268 9.40918 7.56518 9.48501 7.53602 9.55501C7.50685 9.63085 7.46602 9.68918 7.41352 9.74751C7.35518 9.80001 7.29102 9.84085 7.22102 9.87001C7.15102 9.89918 7.07518 9.91668 6.99935 9.91668Z"
        fill="#68686B"/>
    </svg>
  );
};