import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { useTypedSelector } from 'store/store';
import { toggleCollapse } from './collapseSlice';

export const useCollapse = (id: string) => {
  // todo need context for collapse component
  const dispatch = useAppDispatch();
  const {currentCollapsedId} = useTypedSelector(state => state.collapse);

  const onClick = useCallback((id: string) => {
    const collapsedId = currentCollapsedId === id ? null : id;
    dispatch(toggleCollapse(collapsedId));
  }, [dispatch, currentCollapsedId]);

  return {
    isCollapsed: id !== currentCollapsedId,
    onClick
  }
}