import React, { FC } from 'react';
import { SXTypography } from 'scribexUI';
import { Col, Row, Space } from 'componnets';
import { capitalizeWords } from 'utils/helpers';
import styles from './styles.module.scss';

const {Text} = SXTypography;

interface IJobDetails {
  title: string
  details: string[]
}

export const JobDetails: FC<IJobDetails> = React.memo((props) => {
  const {title, details} = props;
  return (
    <Row gutter={[0, {xs: 12, sm: 16}]} className={styles.job_details}>
      <Text weight={'medium'} className={styles.title}>{capitalizeWords(title)}</Text>
      <Row gutter={[0, 8]}>
        {details.map(_detail => {
          const uniqueKey = Math.random().toString(16).slice(2) + Date.now();
          return <Space size={8} className={'w-100'} key={uniqueKey}>
            <Col className={styles.square}/>
            <Text weight={'light'} color={'#68686B'} className={styles.detail}>{_detail}</Text>
          </Space>
        })}
      </Row>
    </Row>
  )
})