import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { ButtonProps, ButtonType } from 'scribexUI/typings';

const sizes: Record<ButtonType["size"], any> = {
  lg: {
    padding: '8px',
    width: '52px',
    height: '52px'
  },
  md: {
    padding: '8px',
    width: '48px',
    height: '48px'
  },
  sm: {
    padding: '6px',
    width: '40px',
    height: '40px'
  },
  xs: {
    padding: '7px',
    width: '34px',
    height: '34px'
  },
};
const roundness: Record<ButtonType["roundness"], any> = {
  pill: {
    borderRadius: "50%"
  },
  rounded: {
    borderRadius: "8px"
  },
  sharp: {
    borderRadius: "none"
  },
};

const iconSizes: Record<ButtonType["size"], any> = {
  lg: {
    width: '32px',
    height: '32px'
  },
  md: {
    width: '32px',
    height: '32px'
  },
  sm: {
    width: '28px',
    height: '28px'
  },
  xs: {
    width: '18px',
    height: '18px'
  },
}

const buttonStyles = css<ButtonProps>`
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => sizes[props.size as ButtonType["size"]]}
  ${(props) => roundness[props.roundness as ButtonType["roundness"]]}
  ${(props) =>
          props.type === 'primary' && css<ButtonProps>`
            background-color: ${props.theme.colors.primary};
            border: none;
          `
  }
  ${(props) =>
          props.type === 'secondary' && css<ButtonProps>`
            background-color: #E6E6E6;
            border: none;
          `
  }
  ${(props) =>
          props.type === 'tertiary' && css<ButtonProps>`
            background-color: transparent;
            border: 2px solid ${props.theme.colors.primary};
          `
  }
  ${(props) =>
          props.disabled && css<ButtonProps>`
            background-color: ${(props) => props.type === "tertiary" ? 'white' : props.theme.colors.disabledBackground};
            border: ${(props) => props.type === "tertiary" ? `2px solid ${props.theme.colors.primaryDisabled}` : 'none'};
            color: ${(props) => props.theme.colors.primaryDisabled};
            cursor: not-allowed;
          `
  }
  &:hover:not([disabled]) {
    ${(props) =>
            props.type === 'primary' && css<ButtonProps>`
              background-color: ${props.theme.colors.primaryHover};
            `
    }
    ${(props) =>
            props.type === 'tertiary' && css<ButtonProps>`
              color: ${props.theme.colors.primaryHover};
              border-color: ${props.theme.colors.primaryHover};
            `
    }
`;
const StyledButton = styled.button<ButtonProps>`
  ${buttonStyles}
`;

const StyledIconWrapper = styled.span<Record<string, any>>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => iconSizes[props.size as ButtonType["size"]]}
  ${(props) =>
          props.type === 'primary' && css<ButtonProps>`
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(46deg) brightness(100%) contrast(103%);
          `
  }
  ${(props) =>
          props.type === 'secondary' && css<ButtonProps>`
            filter: brightness(0) saturate(100%) invert(11%) sepia(0%) saturate(27%) hue-rotate(185deg) brightness(104%) contrast(95%);
          `
  }
  ${(props) =>
          props.type === 'tertiary' && css<ButtonProps>`
            filter: brightness(0) saturate(100%) invert(35%) sepia(88%) saturate(952%) hue-rotate(211deg) brightness(95%) contrast(100%);
          `
  }
  ${(props) =>
          props.disabled && css<ButtonProps>`
            filter: brightness(0) saturate(100%) invert(72%) sepia(8%) saturate(25%) hue-rotate(202deg) brightness(93%) contrast(90%);
          `
  }
`;

export const SXIconButton: FC<ButtonProps> = React.memo((props) => {
  const {
    type = 'secondary',
    size = 'md',
    roundness = 'rounded',
    children,
    onClick,
    disabled
  } = props;

  return (
    <StyledButton
      type={type}
      size={size}
      roundness={roundness}
      onClick={onClick}
      disabled={disabled}
    >
      <StyledIconWrapper size={size} type={type} disabled={disabled}>{children}</StyledIconWrapper>
    </StyledButton>
  )
})