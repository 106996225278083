import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseUrl = process.env.REACT_APP_API_URL;

export type AppUserData = {
  email: string,
  name: string,
  type: string,
  password: string,
  passwordRepeat: string
};

export const api = createApi({
  tagTypes: ['User', 'Admin', 'Country', 'Language', 'HomeCountries', 'AdminSettings'],
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl,
    // credentials: 'include',
    prepareHeaders: async (headers) => {
      const xsrfToken = localStorage.getItem('xsrf_token');
      if (xsrfToken) {
        // headers.set('X-XSRF-TOKEN', xsrfToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    template: builder.mutation<any, any>({
      query: (data) => ({
        url: 'scribex/home/template',
        method: 'post',
        body: data,
      }),
    }),
    jobList: builder.query<any, void>({
      query: () => ({
        url: '/scribex/api/job/publish/list',
        method: 'get',
      }),
    }),
    formTemplate: builder.mutation<any, any>({
      query: (data) => ({
        url: '/scribex/api/form/create',
        method: 'post',
        body: data,
      }),
    }),

  })
});

export const apiAuthMiddleware = () => (next: any) => (action: any) => {
  if (action.type.startsWith('api/') && action.meta) {
    if (action.meta.requestStatus === 'fulfilled') {
      const newToken = action.meta.baseQueryMeta.response.headers.get('X-XSRF-TOKEN');
      if (newToken) {
        // localStorage.setItem('xsrf_token', newToken);
      }
    }
  }
  return next(action);
};
