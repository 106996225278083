import React from 'react';
import { Col, Image } from 'antd';
import { servicesData } from 'mock';
import { Service } from 'componnets';
import { SXTypography } from 'scribexUI';
import img from 'assets/images/services-top-shedow.webp';
import styles from './styles.module.scss';

const {Text} = SXTypography;

export const Services = React.memo(() => {
  return (
    <Col span={24} className={styles.services}>
      <Col className={styles.top_banner} span={24}>
        <Image loading={'eager'} src={img} preview={false} alt={'Image'} className={styles.image}/>
        <Col className={styles.top_banner_background}>
          <Text color={'#ffffff'} className={styles.title}>SERVICES</Text>
        </Col>
      </Col>
      <Col className={styles.services_container}>
        {servicesData.map((_service) => <Service key={_service.id} title={_service.title}
                                                 description={_service.description} optionList={_service.optionList}/>)}
      </Col>
    </Col>
  )
})