import { useCallback } from 'react';
import { ROUTES } from 'constants/routes';
import { useNavigate } from 'react-router-dom';

export const useJobDetails = () => {
  const navigate = useNavigate();

  const onLinkClick = useCallback((id:string) => {
    navigate(ROUTES.positionDetails, {
      state:{
        id
      }
    });
  }, [navigate]);

  return {
    onLinkClick
  }
}