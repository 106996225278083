import React, { FC } from 'react';
import { HomeArrowIcon, Row, Col } from 'componnets';
import { SXTextButton, SXTypography } from 'scribexUI';
import styles from './styles.module.scss'
import { useJobDetails } from './useJobDetails';
import { JobTypeMapping } from 'constants/mappings';

const {Text} = SXTypography;

interface IJobDetails {
  title: string
  jobType: string
  location: string
  id: string
}

export const JobProfile: FC<IJobDetails> = React.memo((props) => {
  const {title, jobType, location, id} = props;
  const {onLinkClick} = useJobDetails();
  return (
    <Row className={styles.job_details} justify={'space-between'} align={'middle'} gutter={[0, 10]}>
      <Col sm={8} xs={24}>
        <Text className={styles.title} weight={'medium'} color={'#FFFFFF'} size={'lg'}>{title}</Text>
      </Col>
      <Col sm={3} xs={5}>
        <Text className={styles.job_info} color={'rgba(241, 250, 255, 0.7)'} size={'lg'}>{JobTypeMapping[jobType]}</Text>
      </Col>
      <Col sm={4} xs={5}>
        <Text className={styles.job_info} color={'rgba(241, 250, 255, 0.7)'} size={'lg'}>{location}</Text>
      </Col>
      <Col sm={3} xs={24} className={styles.button}>
        <SXTextButton
          className={styles.apply_button}
          hoverColor={'#15A6FF'}
          size={'lg'}
          onClick={() => onLinkClick(id)}
          color={'#FFFFFF'}
          $right_icon={<HomeArrowIcon color={'#ffffff'}/>}>
          APPLY
        </SXTextButton>
      </Col>
    </Row>
  )
})