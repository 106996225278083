export const ShowIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9999 15.9696C9.80909 15.9696 8.03076 14.1912 8.03076 12.0004C8.03076 9.80958 9.80909 8.03125 11.9999 8.03125C14.1908 8.03125 15.9691 9.80958 15.9691 12.0004C15.9691 14.1912 14.1908 15.9696 11.9999 15.9696ZM11.9999 9.40625C10.5699 9.40625 9.40576 10.5704 9.40576 12.0004C9.40576 13.4304 10.5699 14.5946 11.9999 14.5946C13.4299 14.5946 14.5941 13.4304 14.5941 12.0004C14.5941 10.5704 13.4299 9.40625 11.9999 9.40625Z"
        fill="#222222"/>
      <path
        d="M12.0001 20.2681C8.55341 20.2681 5.29924 18.2514 3.06257 14.7498C2.09091 13.2373 2.09091 10.7714 3.06257 9.24978C5.30841 5.74811 8.56257 3.73145 12.0001 3.73145C15.4376 3.73145 18.6917 5.74811 20.9284 9.24978C21.9001 10.7623 21.9001 13.2281 20.9284 14.7498C18.6917 18.2514 15.4376 20.2681 12.0001 20.2681ZM12.0001 5.10645C9.03924 5.10645 6.20674 6.88478 4.22674 9.99228C3.53924 11.0648 3.53924 12.9348 4.22674 14.0073C6.20674 17.1148 9.03924 18.8931 12.0001 18.8931C14.9609 18.8931 17.7934 17.1148 19.7734 14.0073C20.4609 12.9348 20.4609 11.0648 19.7734 9.99228C17.7934 6.88478 14.9609 5.10645 12.0001 5.10645Z"
        fill="#222222"/>
    </svg>

  )
}