import { api } from 'api';
import { FormProps, Form, notification } from 'antd';
import { ROUTES } from 'constants/routes';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMappingValueType } from 'typings';

type FieldType = {
  CUSTOM_SOFTWARE: boolean;
  UI_UX: boolean;
  MVP: boolean;
  MOBILE_APP: boolean;
  SOMETHING_ELSE: boolean;
  EMAIL: string;
  PHONE: string;
  COMPANY: string;
  MESSAGE: string;
  FILE: string;
  NAME: string;
};
type CheckboxListType = {
  name: 'CUSTOM_SOFTWARE' | 'UI_UX' | 'MVP' | 'MOBILE_APP' | 'SOMETHING_ELSE' | 'COMPANY' | 'MESSAGE' | 'FILE' | 'NAME';
  label: 'Custom Software' | 'UI UX' | 'MVP' | 'Mobile App' | 'Something Else' | 'COMPANY' | 'MESSAGE' | 'FILE' | 'NAME';
};

const emailRegexp = new RegExp(/^[\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,}$/);
const checkboxList: CheckboxListType[] = [
  {
    name: 'CUSTOM_SOFTWARE',
    label: 'Custom Software'
  },
  {
    name: 'UI_UX',
    label: 'UI UX'
  },
  {
    name: 'MOBILE_APP',
    label: 'Mobile App'
  },
  {
    name: 'MVP',
    label: 'MVP'
  },
  {
    name: 'SOMETHING_ELSE',
    label: 'Something Else'
  }];

export const useForm = () => {
  const [form] = Form.useForm();
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [checkboxesIsValid, setCheckboxesIsValid] = useState(true);
  const [file, setFile] = useState<File | null>(null);
  const navigate = useNavigate();
  const [template, {isLoading}] = api.useTemplateMutation();
  const [notificationApi, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = useCallback((messageContent: ErrorMappingValueType) => {
    const {type, message} = messageContent;
    notificationApi[type]({
      message: message,
    });
  }, [notificationApi]);

  const validateFields = useCallback((formFields: FieldType) => {
    const checkboxValues = checkboxList.map((_checkbox: CheckboxListType) => formFields[_checkbox.name]);
    const checkboxValidation = checkboxValues.some(_value => _value);
    const emailValidation = emailRegexp.test(formFields.EMAIL);
    const phoneValidation = formFields.PHONE ? !isNaN(+formFields.PHONE) : true;
    setEmailIsValid(emailValidation);
    setPhoneIsValid(phoneValidation);
    setCheckboxesIsValid(checkboxValidation);
    return emailValidation && phoneValidation && checkboxValidation;
  }, []);

  const onFinish: FormProps<FieldType>['onFinish'] = useCallback((values: FieldType) => {
    const isValid = validateFields(values);
    const checkboxValues: string[] = [];
    const formData = new FormData();
    if (isValid) {
      checkboxList.forEach((_checkbox: CheckboxListType) => {
        if (values[_checkbox.name]) {
          checkboxValues.push(_checkbox.name);
        }
      });
      const payload: Record<string, any> = {
        type: checkboxValues.join(','),
        name: values.NAME,
        company: values.COMPANY,
        phoneNumber: values.PHONE ? values.PHONE : '',
        email: values.EMAIL,
        message: values.MESSAGE,
        file: file,
      }

      Object.keys(payload).forEach((key: string) => {
        if (key === 'file' && payload[key]) {
          formData.append("file", payload[key], payload[key]?.name);
        } else {
          formData.append(key, payload[key]);
        }
      });

      template(formData)
        .then(({data, error}: any) => {
          if (error && error.data) {
            const errorMessage = Object.values(error.data)[0] as string;
            openNotificationWithIcon({type: 'error', message: errorMessage});
          } else if (data?.status && data?.status === "ok") {
            navigate(ROUTES.formSubmitted);
          }
        })
        .catch(err => {
          console.log(err, 'error')
        })
    }
  }, [template, validateFields, navigate, file, openNotificationWithIcon]);

  const onInputBlur = useCallback(({value, type}: Record<string, string>) => {
    if (type === 'email') {
      setEmailIsValid(emailRegexp.test(value));
    } else if (type === 'phone') {
      if (value && isNaN(+value)) {
        setPhoneIsValid(false);
      } else {
        setPhoneIsValid(true);
      }
    }
  }, []);
  const handleFileChange = (selectedFile: File | null) => {
    setFile(selectedFile);
  };

  return {
    onFinish,
    onInputBlur,
    handleFileChange,
    emailIsValid,
    phoneIsValid,
    checkboxesIsValid,
    form,
    file,
    checkboxList,
    contextHolder,
    isLoading
  }
}