import { ListElement } from './List';
import React, { useCallback, useState } from 'react';
import styled, { css, CSSProp } from 'styled-components';

type ListSizeType = "sm" | 'md' | 'fill';
type ListModeType = 'transparent' | 'default';

type StyledListContainerType = {
  $size: ListSizeType;
  $mode: ListModeType
}

export type ListType = {
  label: string;
  icon?: React.ReactElement;
  button?: string | null;
  tag?: string;
  selectable?: boolean;
  children?: ListType[];
  id: string,
  img?: string,
  key?: string
}

interface ListProps {
  list: ListType[];
  button?: string | null;
  bordered?: boolean;
  size?: ListSizeType;
  mode?: 'transparent' | 'default';
  selectable?: boolean;
  buttonType?: 'radio' | 'checkbox'
  onClick?: (path: string) => void;
  onSelect?: (item: string) => void;
  onButtonClick?: (id?: string, label?:string) => void;
  onRemoveDefault?: (item: any) => void;
}

const sizes: Record<ListSizeType, CSSProp> = {
  sm: {
    maxWidth: '200px',
    maxHeight: '196px',
  },
  md: {
    maxWidth: '300px',
    maxHeight: '304px',
  },
  fill: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}

const ListContainer = styled.div<StyledListContainerType>`
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  overflow: auto;
  background: #FBFBFB;
  box-shadow: 0px 4px 8px 0px #00000014;
  box-sizing: border-box;

  ${(props) => sizes[props.$size]}
  ${(props) => props.$mode === 'transparent' && css`
    background: transparent;
    box-shadow: none;
    padding: 0;
  `}
  & > :first-child > li > div {
    border-top: none;
  }
`;

export const SXList: React.FC<ListProps> = (props) => {
  const {
    list,
    size = 'md',
    bordered = false,
    button,
    mode = 'default',
    selectable,
    buttonType = 'checkbox',
    onClick,
    onSelect,
    onButtonClick,
    onRemoveDefault
  } = props;
  const [selectedOption, setSelectedOption] = useState('');
  const onCheck = useCallback((updateInfo: any) => {
    buttonType === 'radio' && setSelectedOption(updateInfo.id);
    onSelect && onSelect(updateInfo);
  }, [onSelect, buttonType]);

  return (
    <ListContainer $size={size} $mode={mode}>
      {list.map((_list) => <ListElement
        {..._list}
        onRemoveDefault={onRemoveDefault}
        selectable={selectable}
        buttonType={buttonType}
        selectedOption={selectedOption}
        button={button}
        onClick={onClick}
        onButtonClick={onButtonClick}
        onSelect={onCheck}
        size={size}
        bordered={bordered}
        key={_list.id}
        countryKey={_list.key}
        id={_list.id}/>)}
    </ListContainer>
  );
};