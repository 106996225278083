import React from 'react';
import { Col, Row } from 'componnets';
import { HomeArrowIcon } from 'componnets';
import { useContacts } from './useContacts';
import { SXTextButton, SXTypography } from 'scribexUI';
import styles from './styles.module.scss';

const {Text} = SXTypography;
const address = [
  "14 Level Boulevard Plaza, Tower 1",
  "Downtown, Dubai",
  "PO Box 2114472"
];
const workSchedule = [
  "Monday-Friday",
  "8:30AM-7:00PM",
];
export const Contacts = React.memo(() => {
  const {onProjectFormClick} = useContacts();
  return (
    <Row className={styles.contacts} justify={'space-between'}>
      <Col span={24}>
        <Row gutter={[0, 40]}>
          <Col className={styles.title_container} span={24}>
            <Text className={styles.contact_us}>CONTACT US</Text>
            <a href="mailto:info@scribex.io">
              <Text className={styles.email}>info@scribex.io</Text>
            </a>
          </Col>
          <Col className={styles.address_container} span={24}>
            {address.map((_address) => <Text className={styles.address} key={_address}>{_address}</Text>)}
          </Col>
          <Col span={24} className={styles.work_schedule_container}>
            {workSchedule.map((_workSchedule) => <Text className={styles.address}
                                                       key={_workSchedule}>{_workSchedule}</Text>)}
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row align={'bottom'} justify={'space-between'} style={{height: '100%'}} className={styles.bottom_container}>
          <Col>
            <a href="tel:+971 44 55 85 40">
              <Text weight={'medium'} color={'#331DAD'} className={styles.phone_link}>
                +971 44 55 85 40
              </Text>
            </a>
          </Col>
          <Col>
            <SXTextButton
              onClick={onProjectFormClick}
              className={styles.phone_link}
              hoverColor={'#15A6FF'}
              color={'#331DAD'}
              $right_icon={<HomeArrowIcon color={'#331DAD'}/>}>
              GOT A PROJECT IN MIND?
            </SXTextButton>
          </Col>
        </Row>
      </Col>
    </Row>
  )
})