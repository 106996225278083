import { viewportSize } from 'utils/helpers';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { api } from 'api';

export const useCareers = () => {
  const {width} = viewportSize();
  const navigate = useNavigate();
  const {data = []} = api.useJobListQuery();

  const onReachOut = useCallback(() => {
    navigate(ROUTES.contact);
  }, [navigate]);

  return {
    width,
    onReachOut,
    data
  }
}