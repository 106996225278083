import React, { lazy, Suspense } from 'react';
import { ROUTES } from 'constants/routes';
import { Route, RouterProvider } from 'react-router-dom';
import { Row, Button, Result, FullWidthLayout } from 'componnets';
import { createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import {
  Home,
  ProjectForm,
  FormSubmitted,
  NotAuthorized,
  Contacts,
} from 'pages';

const LazyTeam = lazy(() => import('pages')
  .then(({Team}) => ({default: Team}))
);
const LazyCredo = lazy(() => import('pages')
  .then(({Credo}) => ({default: Credo}))
);
const LazyServices = lazy(() => import('pages')
  .then(({Services}) => ({default: Services}))
);
const LazyCareers = lazy(() => import('pages')
  .then(({Careers}) => ({default: Careers}))
);
const LazyPositionDetails = lazy(() => import('pages')
  .then(({PositionDetails}) => ({default: PositionDetails}))
);

export const Navigation = React.memo(() => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={ROUTES.home} element={<FullWidthLayout/>}>
          <Route index element={<Home/>}/>
          <Route path={ROUTES.projectForm} element={<ProjectForm/>}/>
          <Route path={ROUTES.formSubmitted} element={<FormSubmitted/>}/>
          <Route path={ROUTES.contact} element={<Contacts/>}/>
          <Route path={ROUTES.credo} element={
            <Suspense>
              <LazyCredo/>
            </Suspense>}/>
          <Route path={ROUTES.team} element={
            <Suspense>
              <LazyTeam/>
            </Suspense>
          }/>
          <Route path={ROUTES.services} element={
            <Suspense>
              <LazyServices/>
            </Suspense>
          }/>
          <Route path={ROUTES.careers} element={
            <Suspense>
              <LazyCareers/>
            </Suspense>
          }/>
          <Route path={ROUTES.positionDetails} element={
            <Suspense>
              <LazyPositionDetails/>
            </Suspense>
          }/>
        </Route>
        <Route path={ROUTES.notAuthorized} element={<NotAuthorized/>}/>
        <Route path={'*'} element={
          <Row justify={'center'} align={'middle'} style={{backgroundColor: '#ffffff', minHeight: '100vh'}}>
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
              extra={
                <Button onClick={() => history.back()} type="primary">
                  Back
                </Button>
              }
            />
          </Row>
        }/>
      </>
    )
  )
  return (
    <RouterProvider router={router}/>
  )
})