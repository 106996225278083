export const HideIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.68076 14.0071C8.5066 14.0071 8.33243 13.9429 8.19493 13.8054C7.44326 13.0538 7.03076 12.0546 7.03076 11.0004C7.03076 8.80958 8.80909 7.03125 10.9999 7.03125C12.0541 7.03125 13.0533 7.44375 13.8049 8.19542C13.9333 8.32375 14.0066 8.49792 14.0066 8.68125C14.0066 8.86458 13.9333 9.03875 13.8049 9.16708L9.16659 13.8054C9.02909 13.9429 8.85493 14.0071 8.68076 14.0071ZM10.9999 8.40625C9.56993 8.40625 8.40576 9.57042 8.40576 11.0004C8.40576 11.4587 8.52493 11.8987 8.74493 12.2837L12.2833 8.74542C11.8983 8.52542 11.4583 8.40625 10.9999 8.40625Z"
        fill="#222222"/>
      <path
        d="M5.13319 16.9673C4.97736 16.9673 4.81236 16.9123 4.68402 16.8023C3.70319 15.9681 2.82319 14.9414 2.07152 13.7498C1.09986 12.2373 1.09986 9.77145 2.07152 8.24978C4.30819 4.74811 7.56236 2.73145 10.9999 2.73145C13.0165 2.73145 15.0057 3.42811 16.7474 4.73895C17.0499 4.96811 17.114 5.39895 16.8849 5.70145C16.6557 6.00395 16.2249 6.06811 15.9224 5.83895C14.419 4.70228 12.714 4.10645 10.9999 4.10645C8.03902 4.10645 5.20652 5.88478 3.22652 8.99228C2.53902 10.0648 2.53902 11.9348 3.22652 13.0073C3.91402 14.0798 4.70236 15.0056 5.57319 15.7573C5.85736 16.0048 5.89402 16.4356 5.64652 16.7289C5.51819 16.8848 5.32569 16.9673 5.13319 16.9673Z"
        fill="#222222"/>
      <path
        d="M10.9996 19.2685C9.78044 19.2685 8.58878 19.021 7.44294 18.5352C7.09461 18.3885 6.92961 17.9852 7.07628 17.6369C7.22294 17.2885 7.62628 17.1235 7.97461 17.2702C8.94628 17.6827 9.96378 17.8935 10.9904 17.8935C13.9513 17.8935 16.7838 16.1152 18.7638 13.0077C19.4513 11.9352 19.4513 10.0652 18.7638 8.9927C18.4796 8.54354 18.1679 8.1127 17.8379 7.70937C17.5996 7.41604 17.6454 6.9852 17.9388 6.7377C18.2321 6.49937 18.6629 6.53604 18.9104 6.83854C19.2679 7.27854 19.6163 7.7552 19.9279 8.2502C20.8996 9.7627 20.8996 12.2285 19.9279 13.7502C17.6913 17.2519 14.4371 19.2685 10.9996 19.2685Z"
        fill="#222222"/>
      <path
        d="M11.6321 14.914C11.3113 14.914 11.018 14.6848 10.9538 14.3548C10.8805 13.979 11.128 13.6215 11.5038 13.5573C12.5121 13.374 13.3555 12.5307 13.5388 11.5223C13.6121 11.1465 13.9696 10.9082 14.3455 10.9723C14.7213 11.0457 14.9688 11.4032 14.8955 11.779C14.6021 13.3648 13.3371 14.6207 11.7605 14.914C11.7146 14.9048 11.678 14.914 11.6321 14.914Z"
        fill="#222222"/>
      <path
        d="M1.83377 20.854C1.6596 20.854 1.48543 20.7898 1.34793 20.6523C1.0821 20.3865 1.0821 19.9465 1.34793 19.6807L8.19543 12.8332C8.46127 12.5673 8.90127 12.5673 9.1671 12.8332C9.43293 13.099 9.43293 13.539 9.1671 13.8048L2.3196 20.6523C2.1821 20.7898 2.00793 20.854 1.83377 20.854Z"
        fill="#222222"/>
      <path
        d="M13.3189 9.36865C13.1447 9.36865 12.9705 9.30448 12.833 9.16698C12.5672 8.90115 12.5672 8.46115 12.833 8.19531L19.6805 1.34781C19.9464 1.08198 20.3864 1.08198 20.6522 1.34781C20.918 1.61365 20.918 2.05365 20.6522 2.31948L13.8047 9.16698C13.6672 9.30448 13.493 9.36865 13.3189 9.36865Z"
        fill="#222222"/>
    </svg>

  )
}