import React from 'react';
import { Col, Row, Space, Form as AntdForm, ErrorIcon } from 'componnets';
import { SXButton, SXCheckbox, SXInput, SXPhoneInput, SXTextArea, SXUpload, SXTypography } from 'scribexUI';
import styles from '../styles.module.scss';
import { useForm } from './useForm';

const {Text} = SXTypography;

export const Form = React.memo(() => {
  const {
    handleFileChange,
    onInputBlur,
    onFinish,
    phoneIsValid,
    form,
    checkboxesIsValid,
    emailIsValid,
    file,
    checkboxList,
    contextHolder,
    isLoading
  } = useForm();
  return (
    <>
      {contextHolder}
      <AntdForm
        form={form}
        onFinish={onFinish}
        initialValues={{remember: false}}
        autoComplete={'off'}
      >
        <Row gutter={[0, {xs: 24, sm: 32}]}>
          <Col span={24}>
            <Row gutter={[0, 4]} className={styles.checkbox_container}>
              <Text className={styles.form_title}>WHAT IS IT ABOUT?</Text>
              <Space size={16} className={'w-100'} wrap={true}>
                {checkboxList.map(_checkbox => <AntdForm.Item
                  key={_checkbox.name}
                  name={_checkbox.name}
                  valuePropName="checked">
                  <SXCheckbox
                    hoverColor={'#331DAD'} selectedColor={'#331DAD'} color={'#140D0B'}
                    title={_checkbox.label}/>
                </AntdForm.Item>)}
              </Space>
              {!checkboxesIsValid &&
                <Row className={styles.error} align={'middle'}>
                  <ErrorIcon/>
                  <Text size={'sm'} className={styles.error_text}>Please select the type of request</Text>
                </Row>}
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <Row className={styles.form_fields} wrap={true}>
                      <Col>
                        <AntdForm.Item name={'NAME'}>
                          <SXInput inpSize={'fill'} line placeholder={'Full Name'}/>
                        </AntdForm.Item>
                      </Col>
                      <Col>
                        <AntdForm.Item name={'COMPANY'}>
                          <SXInput inpSize={'fill'} line placeholder={'Company'}/>
                        </AntdForm.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row className={styles.form_fields} wrap={true}>
                      <Col>
                        <AntdForm.Item name={'PHONE'}>
                          <SXPhoneInput
                            disabled={false}
                            inpSize={'fill'}
                            line
                            placeholder={'Phone Number'}
                            onBlur={(e) => onInputBlur({value: e.target.value, type: 'phone'})}
                            isValid={phoneIsValid}
                            validationInfo={{
                              error: 'Wrong phone number format'
                            }}/>
                        </AntdForm.Item>
                      </Col>
                      <Col>
                        <AntdForm.Item name={'EMAIL'}>
                          <SXInput
                            disabled={false}
                            inpSize={'fill'}
                            line placeholder={'Email'}
                            onBlur={(e) => onInputBlur({value: e.target.value, type: 'email'})}
                            isValid={emailIsValid}
                            validationInfo={{
                              error: 'Wrong email format'
                            }}/>
                        </AntdForm.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <AntdForm.Item name={'MESSAGE'}>
                      <SXTextArea disabled={false} inpSize={'fill'} line placeholder={'Message'}/>
                    </AntdForm.Item>
                  </Col>
                  <Col span={24}>
                    <AntdForm.Item name={'FILE'}>
                      <SXUpload file={file} acceptTypes={".jpg, .jpeg, .png, .docx, .pdf"}
                                onFileChange={handleFileChange}/>
                    </AntdForm.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col flex={'222px'}>
              <SXButton
                htmlType={'submit'}
                block
                type={'primary'}
                roundness={'sharp'}
                size={'lg'}
                isLoading={isLoading}
                bgColor={'#140D0B'}>
                Submit
              </SXButton>
          </Col>
        </Row>
      </AntdForm>
    </>
  )
})