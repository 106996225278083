import { FC } from 'react';

interface ICircleDoneIcon {
  color?: string
}

export const CircleDoneIcon: FC<ICircleDoneIcon> = (props) => {
  const {color = '#5865F2'} = props
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill='none' xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2413_7645)">
        <path
          d="M8.19096 4.13672H7.64135C7.52182 4.13672 7.40814 4.19414 7.33783 4.29258L5.49564 6.84727L4.66126 5.68945C4.59095 5.59219 4.47845 5.53359 4.35774 5.53359H3.80813C3.73196 5.53359 3.68743 5.62031 3.73196 5.68242L5.19212 7.70742C5.22662 7.75557 5.27209 7.7948 5.32477 7.82186C5.37745 7.84892 5.43583 7.86304 5.49505 7.86304C5.55428 7.86304 5.61265 7.84892 5.66533 7.82186C5.71802 7.7948 5.76349 7.75557 5.79798 7.70742L8.26596 4.28555C8.31166 4.22344 8.26713 4.13672 8.19096 4.13672Z"
          fill={color}/>
        <path
          d="M6 0.75C3.10078 0.75 0.75 3.10078 0.75 6C0.75 8.89922 3.10078 11.25 6 11.25C8.89922 11.25 11.25 8.89922 11.25 6C11.25 3.10078 8.89922 0.75 6 0.75ZM6 10.3594C3.59297 10.3594 1.64062 8.40703 1.64062 6C1.64062 3.59297 3.59297 1.64062 6 1.64062C8.40703 1.64062 10.3594 3.59297 10.3594 6C10.3594 8.40703 8.40703 10.3594 6 10.3594Z"
          fill={color}/>
      </g>
      <defs>
        <clipPath id="clip0_2413_7645">
          <rect width="12" height="12" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}