import styled from 'styled-components';
import React, { useState } from 'react';
import { ListItemElement } from './ListItem';

type ListSizeType = "sm" | 'md' | 'fill';

type StyledListContainerType = {
  $size: ListSizeType
}

export type ListType = {
  label: string;
  icon?: React.ReactElement;
  button?: string | null;
  tag?: string;
  selectable?: boolean;
  children?: ListType[];
  id: string;
  path?: string
}

interface ListProps extends ListType {
  isChildren?: boolean;
  bordered?: boolean;
  size?: ListSizeType;
  selectable?: boolean;
  buttonType?: 'radio' | 'checkbox';
  onClick?: (path: string) => void;
  onSelect?: (item: string) => void;
  onButtonClick?: (id?: string, label?:string) => void;
  onRemoveDefault?: (item:any) => void;
  countryKey?: string;
  selectedOption?: string;
}


const StyledListContainer = styled.ul<StyledListContainerType>`
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const ListElement: React.FC<ListProps> = (props) => {
  const {
    size = 'md',
    bordered = false,
    isChildren = false,
    onClick,
    id,
    children,
    selectable,
    path,
    onSelect,
    countryKey,
    button,
    buttonType,
    selectedOption,
    onButtonClick,
    onRemoveDefault,
    ...other
  } = props;
  const [listIds, setListIds] = useState<Array<string>>([]);
  return (
    <StyledListContainer
      $size={size}
    >
      <ListItemElement
        {...other}
        id={id}
        onRemoveDefault={onRemoveDefault}
        selectable={selectable}
        buttonType={buttonType}
        countryKey={countryKey}
        bordered={bordered}
        button={button}
        isChildren={isChildren}
        hasChildren={!!children?.length}
        onSelect={onSelect}
        selectedOption={selectedOption}
        onButtonClick={onButtonClick}
        onClick={() => {
          if (listIds.includes(id)) {
            setListIds(prevState => prevState.filter(_id => _id !== id));
          } else {
            setListIds(prevState => [...prevState, id]);
          }
          if (path) {
            onClick && onClick(path)
          }
        }}>
        {listIds.includes(id) && children?.map(_childrenItem => {
          return <ListElement
            button={button}
            onClick={onClick}
            isChildren={true}
            {..._childrenItem}
            bordered={bordered}
            key={_childrenItem.id}/>
        })}
      </ListItemElement>
    </StyledListContainer>
  );
};