import React, { FC } from 'react';
import styled from 'styled-components';
import { SXTypography } from 'scribexUI';

const {Text} = SXTypography;

export type OptionType = {
  value: string | number,
  label: string
}

interface IStyledLiContainer {
  $bordered: boolean
}

interface IListItem {
  bordered?: boolean
  onOptionSelect?: (event: React.MouseEvent, item: OptionType) => void
  options: OptionType[]
  placeholder?: string
  selectedValue: string | number | null
  size?: "sm" | 'md' | 'fill' | 'xs'
}

type StyledListContainerType = {
  $size: "sm" | 'md' | 'fill' | 'xs'
}

const StyledListContainer = styled.ul<StyledListContainerType>`
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  padding: ${({$size}) => $size === 'xs' ? '0px' : '8px'};
  cursor: pointer;

  :first-child {
    border-top: none;
  }
`;

const StyledListItem = styled.li<IStyledLiContainer>`
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;
  list-style-type: none;
  padding: 8px;
  ${(props) => props.$bordered && "border-top: 0.5px solid #E6E6E6"}

`;
export const OptionsSelectList: FC<IListItem> = React.memo((props) => {
  const {
    bordered = true,
    onOptionSelect,
    options,
    placeholder,
    selectedValue,
    size = 'md'
  } = props;
  return (
    <StyledListContainer $size={size}>
      {selectedValue && <StyledListItem
        $bordered={bordered}
        onClick={(event: React.MouseEvent) => {
          onOptionSelect && onOptionSelect(event, {value: '', label: placeholder || ''});
        }}>
        <Text size={'md'} color={'#68686B'}>{placeholder}</Text>
      </StyledListItem>}
      {options.map(_option => {
        const id = Math.random().toString(16).slice(2) + Date.now();
        return <StyledListItem
          key={id}
          $bordered={bordered}
          onClick={(event: React.MouseEvent) => {
            onOptionSelect && onOptionSelect(event, {value: _option.value, label: _option.label});
          }}>
          <Text size={'md'} color={'#68686B'}>{_option.label}</Text>
        </StyledListItem>
      })}
    </StyledListContainer>
  )
})