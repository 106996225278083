import React, { forwardRef, useCallback } from 'react';
import styled, { css } from 'styled-components';

interface ISXRadioButton {
  disabled?: boolean;
  value?: string;
  selectedOption?: string;
  onChange?: (value: string) => void;
}
interface IStyledRadioContainer {
  checked: boolean;
  disabled?: boolean;
}

type StyledRadioChecked = {
  disabled: boolean
}

const StyledRadioContainer = styled.div<IStyledRadioContainer>`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${({checked}) => checked ? '#5865F2' : '#D5D5D5'};
  background-color: #ffffff;
  box-sizing: border-box;
  ${({disabled}) => disabled && css`
    border: 1px solid #D5D5D5;
    cursor: not-allowed;
  `}
  ${({checked}) => !checked && css`
    &:hover:not([disabled]) {
    'background-color: #F8F8F8'
    }
  `};
`;

const StyledRadioChecked = styled.div<StyledRadioChecked>`
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #5865F2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  ${({disabled}) => disabled && css`
    background-color: #D5D5D5;
  `}
`;

const StyledRadioInput = styled.input`
  appearance: none;
  outline: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export const SXRadioButton = forwardRef<HTMLInputElement, ISXRadioButton>((props, ref) => {
  const { disabled = false, onChange, value, selectedOption} = props;

  const handleChange = useCallback((event: any) => {
    !disabled && onChange && onChange(event.target.value);
  }, [disabled, onChange]);

  return (
    <StyledRadioContainer checked={value === selectedOption} disabled={disabled}>
      <StyledRadioInput
        value={value}
        onChange={(event) => handleChange(event)}
        ref={ref}
        type="radio"
        checked={value === selectedOption}
      />
      {value === selectedOption && <StyledRadioChecked disabled={disabled}/>}
    </StyledRadioContainer>
  );
});