import { useSelector } from 'react-redux';
import { api, apiAuthMiddleware } from 'api';
import { TypedUseSelectorHook } from 'react-redux';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { collapseSlice } from 'componnets/common/collapse/collapseSlice';
// import auth from 'pages/auth/authSlice';

export const store = configureStore({
  reducer: {
    // auth,
    collapse: collapseSlice.reducer,
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, apiAuthMiddleware),

  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: false
  //   }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export const useTypedUserData = () => useTypedSelector((state: RootState) => state.api.queries?.['authenticate("user_data")']?.data) as any;

