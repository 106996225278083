import React from 'react';
import { Col, Image, Row } from 'antd';
import { ROUTES } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import Img from 'assets/icons/done.png';
import { SXButton, SXTypography } from 'scribexUI';
import styles from './styles.module.scss'

const {Title, Text} = SXTypography;

export const FormSubmitted = React.memo(() => {
  const navigate = useNavigate();
  return (
    <Col span={24}>
      <Row className={styles.form_submitted}>
        <Row className={styles.content} justify={'center'} align={'middle'} gutter={[0, 12]}>
          <Col span={24} className={'center'} style={{padding: "14px"}}>
            <Image src={Img} alt={"Image"} preview={false} width={34}/>
          </Col>
          <Col span={24} className={'center'}>
            <Title level={3}>Thank You</Title>
          </Col>
          <Col span={24} className={'center'}>
            <Text color={'#68686B'}>
              Thank you for reaching out to us! <br/>
              We have received your message and will be in touch soon.
            </Text>
          </Col>
          <Col span={24} className={styles.bottom_container}>
            <Row justify={'center'} align={'middle'}>
              <SXButton type={'primary'} roundness={'sharp'} size={'md'} className={styles.button}
                        bgColor={'#140D0B'} onClick={() => navigate(ROUTES.home)}>Go Home</SXButton>
            </Row>
          </Col>
        </Row>
      </Row>
    </Col>
  )
})