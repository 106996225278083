import { Row } from 'componnets';
import React, { FC } from 'react';
import { SXTypography } from 'scribexUI';
import styles from './styles.module.scss';

const {Text} = SXTypography;
interface IJobPositionLabels {
  labelList: string[]
}

export const JobPositionLabels: FC<IJobPositionLabels> = React.memo((props) => {
  const {labelList} = props;
  return (
    <Row className={styles.position_labels}>
      {labelList.map((_label) => <Text
        weight={'medium'}
        color={'#331DAD'}
        className={styles.label}
        key={_label}>
        {_label}
      </Text>)}
    </Row>
  )
})