import React, { FC } from 'react';
import { ROUTES } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { Col, Image, Row, Space } from 'componnets';
import { SXTextButton, SXTypography } from 'scribexUI';
import { QuotationMarksWrapper } from './QuotationMarksWrapper';
import styles from './styles.module.scss';

const {Text} = SXTypography;

export interface IPersonalCard {
  image?: string
  name?: string
  position?: string
  title?: string
  specialTitle?: string
  background?: string
  link?: string
  id: string
}

export const PersonalCard: FC<IPersonalCard> = React.memo((props) => {
  const {
    image,
    position,
    name,
    title,
    background,
    link,
    specialTitle
  } = props;
  const customClassName = title ? 'title' : '';
  const navigate = useNavigate();

  return (
    <Row className={`${styles.personal_card} ${styles[customClassName]}`} style={{background: background}}>
      {image && <Image loading={'eager'} src={image} preview={false} alt={'Image'}/>}
      {name && <Col span={24} className={styles.bottom_box}>
        <Text color={'#ffffff'} className={styles.name}>{name}</Text>
        <Text size={'md'} color={'#ffffff'} className={styles.position}>{position}</Text>
      </Col>}
      {(title || link) && <Space direction={'vertical'} size={6} style={{margin: 'auto 0'}}>
        {title && <Text size={'lg'} color={'#ffffff'} className={styles.title_text}>{title}</Text>}
        {link && <SXTextButton className={styles.link} size={'md'} underline color={'#ffffff'} onClick={() => navigate(ROUTES.careers)}>{link}</SXTextButton>}
      </Space>}
      {specialTitle && <QuotationMarksWrapper>
        <Text size={'lg'} color={'#ffffff'} className={styles.special_title}>{specialTitle}</Text>
      </QuotationMarksWrapper>}
    </Row>
  )
})