import React from 'react';
import { Col, Row } from 'antd';
import { SXTypography } from 'scribexUI';
import styles from './styles.module.scss'
import { usePositionDetails } from './usePositionDetails';
import { JobDetails, JobPositionLabels } from 'componnets';
import { PositionApplyForm } from './positionApplyForm';

const {Text} = SXTypography;

type JobDetailsType = {
  [key: string]: string[];
}

export const PositionDetails = React.memo(() => {
  const {positionDetails, jobLabels, width} = usePositionDetails();
  return (
    <Row className={styles.position_details} wrap={width <= 1200}>
      <Col className={styles.left_banner}>
        <Row gutter={[0, {xs: 24, sm: 32}]}>
          <Text className={styles.title} color={'#331DAD'}>{positionDetails.title?.toUpperCase()}</Text>
          <Col span={24}>
            <Row gutter={[0, {xs: 24, sm: 40}]}>
              <Col>
                <Row gutter={[0, 12]}>
                  <Col>
                    <JobPositionLabels labelList={jobLabels}/>
                  </Col>
                  <Text color={'#68686B'} weight={'light'}
                        className={styles.description}>{positionDetails.description}</Text>
                </Row>
              </Col>
              {Object.entries(positionDetails?.jobDetails as JobDetailsType || {}).map(([key, value]) => {
                return <Col key={key} span={24}>
                  <JobDetails title={key} details={value}/>
                </Col>
              })}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col flex={1} className={styles.right_banner}>
        <PositionApplyForm/>
      </Col>
    </Row>
  )
})