import { createSlice } from '@reduxjs/toolkit';

const initialState: IInitialState = {
  currentCollapsedId: null
}

type IInitialState = {
  currentCollapsedId: string | null
}

export const collapseSlice = createSlice({
  name: 'collapse',
  initialState,
  reducers: {
    toggleCollapse: (state, action) => {
      state.currentCollapsedId = action.payload;
    },
  }
});

export const {
  toggleCollapse,
} = collapseSlice.actions
