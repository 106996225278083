export const LockIcon = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="10" height="10" fill="#D7D7D7"/>
      <path
        d="M-2569 -696C-2569 -697.105 -2568.1 -698 -2567 -698H6558C6559.1 -698 6560 -697.105 6560 -696V962C6560 963.105 6559.1 964 6558 964H-2567C-2568.1 964 -2569 963.105 -2569 962V-696Z"
        fill="#ECEBEB"/>
      <path
        d="M-2567 -697H6558V-699H-2567V-697ZM6559 -696V962H6561V-696H6559ZM6558 963H-2567V965H6558V963ZM-2568 962V-696H-2570V962H-2568ZM-2567 963C-2567.55 963 -2568 962.552 -2568 962H-2570C-2570 963.657 -2568.66 965 -2567 965V963ZM6559 962C6559 962.552 6558.55 963 6558 963V965C6559.66 965 6561 963.657 6561 962H6559ZM6558 -697C6558.55 -697 6559 -696.552 6559 -696H6561C6561 -697.657 6559.66 -699 6558 -699V-697ZM-2567 -699C-2568.66 -699 -2570 -697.657 -2570 -696H-2568C-2568 -696.552 -2567.55 -697 -2567 -697V-699Z"
        fill="#8995FF"/>
      <g clipPath="url(#clip0_0_1)">
        <rect width="1440" height="1024" transform="translate(-971 -330)" fill="white"/>
        <rect x="-971" y="-330" width="1440" height="1024" fill="black" fillOpacity="0.3"/>
        <g filter="url(#filter0_d_0_1)">
          <rect width="640" height="855" transform="translate(-571 -245)" fill="#FBFBFB"/>
          <mask id="path-4-inside-1_0_1" fill="white">
            <path d="M-539 -35C-539 -40.5229 -534.523 -45 -529 -45H27C32.5228 -45 37 -40.5228 37 -35V56H-539V-35Z"/>
          </mask>
          <path d="M-539 -45H37H-539ZM37 57H-539V55H37V57ZM-539 56V-45V56ZM37 -45V56V-45Z" fill="#D5D5D5"
                mask="url(#path-4-inside-1_0_1)"/>
          <rect x="-25" y="-6.5" width="42" height="24" rx="12" fill="#15A6FF"/>
          <circle cx="5" cy="5.5" r="10" fill="white"/>
          <path
            className={'lock-icon'}
            d="M7.8125 3.75H7.5V2.5C7.5 1.12122 6.37878 0 5 0C3.62122 0 2.5 1.12122 2.5 2.5V3.75H2.1875C1.67084 3.75 1.25 4.17038 1.25 4.6875V9.0625C1.25 9.57962 1.67084 10 2.1875 10H7.8125C8.32916 10 8.75 9.57962 8.75 9.0625V4.6875C8.75 4.17038 8.32916 3.75 7.8125 3.75ZM3.33336 2.5C3.33336 1.58081 4.08081 0.833359 5 0.833359C5.91919 0.833359 6.66664 1.58081 6.66664 2.5V3.75H3.33336V2.5Z"
            fill="#15A6FF"/>
        </g>
      </g>
      <defs>
        <filter id="filter0_d_0_1" x="-579" y="-249" width="656" height="871" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="4"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
        </filter>
        <clipPath id="clip0_0_1">
          <rect width="1440" height="1024" fill="white" transform="translate(-971 -330)"/>
        </clipPath>
      </defs>
    </svg>

  )
}