import React from 'react';

export const PlusIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 19" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 18.0684C8.35266 18.0684 7.82812 17.5439 7.82812 16.8966V1.99219C7.82812 1.34484 8.35266 0.820312 9 0.820312C9.64734 0.820312 10.1719 1.34484 10.1719 1.99219V16.8966C10.1719 17.5439 9.64734 18.0684 9 18.0684Z"
        fill="#140D0B"/>
      <path
        d="M16.4522 10.6172H1.54779C0.900447 10.6172 0.375916 10.0927 0.375916 9.44531C0.375916 8.79797 0.900447 8.27344 1.54779 8.27344H16.4522C17.0995 8.27344 17.624 8.79797 17.624 9.44531C17.624 10.0927 17.0995 10.6172 16.4522 10.6172Z"
        fill="#140D0B"/>
    </svg>

  )
}