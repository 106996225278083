export const CookieIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_576_4491)">
        <path
          d="M39.2023 18.4597C36.6294 17.5357 35.0469 14.9173 35.2457 12.8498C35.2457 12.5099 35.042 12.194 34.7845 11.9709C34.5271 11.7488 34.1277 11.6527 33.7935 11.7065C29.8727 12.2817 26.2826 9.41338 25.9268 5.38136C25.8948 5.01746 25.6957 4.69012 25.3867 4.49441C25.0789 4.29988 24.6954 4.25754 24.3556 4.38457C21.1776 5.5484 18.1747 3.44403 16.9765 0.702008C16.7522 0.189276 16.196 -0.0933796 15.6478 0.027948C6.62653 2.04099 7.62939e-05 10.7544 7.62939e-05 20.0001C7.62939e-05 30.9852 9.01488 40 20 40C30.9851 40 39.9999 30.9852 39.9999 20.0001C39.9673 19.3021 39.9625 18.732 39.2023 18.4597ZM8.2227 21.2601C7.57552 21.2601 7.05083 20.7354 7.05083 20.0883C7.05083 19.4409 7.57552 18.9164 8.2227 18.9164C8.86989 18.9164 9.39457 19.4409 9.39457 20.0883C9.39457 20.7354 8.86981 21.2601 8.2227 21.2601ZM12.9688 30.5469C11.0302 30.5469 9.45316 28.9699 9.45316 27.0313C9.45316 25.0927 11.0302 23.5157 12.9688 23.5157C14.9074 23.5157 16.4844 25.0927 16.4844 27.0313C16.4844 28.9699 14.9074 30.5469 12.9688 30.5469ZM14.1406 16.4844C12.8486 16.4844 11.7969 15.4327 11.7969 14.1407C11.7969 12.8487 12.8486 11.797 14.1406 11.797C15.4327 11.797 16.4844 12.8487 16.4844 14.1407C16.4844 15.4327 15.4327 16.4844 14.1406 16.4844ZM23.5156 32.8906C22.2236 32.8906 21.1719 31.8389 21.1719 30.5469C21.1719 29.2549 22.2236 28.2031 23.5156 28.2031C24.8076 28.2031 25.8594 29.2549 25.8594 30.5469C25.8594 31.8389 24.8076 32.8906 23.5156 32.8906ZM24.6875 23.5157C22.7489 23.5157 21.1719 21.9386 21.1719 20.0001C21.1719 18.0615 22.7489 16.4844 24.6875 16.4844C26.6261 16.4844 28.2031 18.0615 28.2031 20.0001C28.2031 21.9386 26.6261 23.5157 24.6875 23.5157ZM31.6601 25.9476C31.0129 25.9476 30.4882 25.4229 30.4882 24.7757C30.4882 24.1284 31.0129 23.6039 31.6601 23.6039C32.3073 23.6039 32.832 24.1284 32.832 24.7757C32.832 25.4229 32.3072 25.9476 31.6601 25.9476Z"
          fill="#222222"/>
      </g>
      <defs>
        <clipPath id="clip0_576_4491">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}