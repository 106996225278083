interface IScribexLogo {
  width?: number
  height?: number
}

export const ScribexLogo = (props: IScribexLogo) => {
  const {height = 62, width = 155} = props;
  return (
    <svg width={width} height={height} viewBox="0 0 131 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 6.00516L131 0V40H0V6.00516Z" fill="#331DAD"/>
      <path
        d="M11.4521 27.156H16.7664C16.7664 28.3814 17.7545 29.3077 19.4921 29.3077C21.2297 29.3077 22.1144 28.6125 22.1144 27.7192C22.1144 24.5403 11.828 28.2164 11.828 20.9652C11.828 17.8193 14.7923 15.7337 19.1522 15.7337C23.5121 15.7337 26.4764 18.0524 26.4764 21.0642H21.2994C21.2994 19.8389 20.3451 19.3417 19.0508 19.3417C18.0965 19.3417 16.9395 19.7729 16.9395 20.6331C16.9395 23.614 27.2259 20.268 27.2259 27.3871C27.2259 30.7641 24.1941 32.9508 19.6631 32.9508C14.4186 32.9508 11.4543 30.566 11.4543 27.156H11.4521Z"
        fill="white"/>
      <path
        d="M28.5156 24.3394C28.5156 19.0418 31.8874 15.7308 37.1679 15.7308C41.6988 15.7308 44.6272 18.0165 45.1382 21.9567H39.7902C39.5178 20.3002 38.7683 19.539 37.1679 19.539C35.0565 19.539 33.965 21.2615 33.965 24.3394C33.965 27.4172 35.0544 29.1398 37.1679 29.1398C38.5635 29.1398 39.484 27.9804 39.7902 26.1919H45.1382C44.5935 30.3301 41.494 32.9459 37.1679 32.9459C31.8874 32.9459 28.5156 29.6349 28.5156 24.3373V24.3394Z"
        fill="white"/>
      <path
        d="M57.6055 16.0631V20.2694C54.6412 19.7392 52.325 21.0306 52.325 24.2096V32.6201H47.1143V16.0631H51.6452L51.9175 18.2478C53.007 16.7913 54.7446 15.599 57.6055 16.0631Z"
        fill="white"/>
      <path d="M59.5801 16.0633H64.7909V32.6203H59.5801V16.0633Z" fill="white"/>
      <path
        d="M84.3519 24.3394C84.3519 29.5709 81.3538 32.9479 76.6877 32.9479C74.4729 32.9479 73.0435 32.0217 72.0217 30.7963L71.7155 32.6179H67.1846V9.00157H72.3953V17.4843C73.4172 16.457 74.7453 15.7287 76.6877 15.7287C81.3538 15.7287 84.3519 19.1058 84.3519 24.3373V24.3394ZM78.9025 24.3394C78.9025 21.1934 77.7434 19.3719 75.5307 19.3719C73.4869 19.3719 72.2602 21.1934 72.2602 24.3394C72.2602 27.4853 73.4869 29.3069 75.5307 29.3069C77.7455 29.3069 78.9025 27.4853 78.9025 24.3394Z"
        fill="white"/>
      <path
        d="M102.381 25.1695H91.208C91.3452 28.1174 92.4009 29.4748 94.2399 29.4748C95.6017 29.4748 96.6574 28.7796 96.9656 27.156H102.28C101.497 30.8652 98.3633 32.9508 94.242 32.9508C88.9974 32.9508 85.7607 29.7058 85.7607 24.3422C85.7607 18.9786 89.0312 15.7337 94.3095 15.7337C99.5879 15.7337 102.723 19.4758 102.383 25.1715L102.381 25.1695ZM91.2439 22.2876H97.1028C97.0015 20.334 95.8424 19.2076 94.3095 19.2076C92.6057 19.2076 91.4149 20.301 91.2439 22.2876Z"
        fill="white"/>
      <path
        d="M113.69 32.6182L110.761 27.0875L108.105 32.6182H102.246L107.833 23.9437L102.996 16.0633H108.582L110.797 20.7317L113.25 16.0633H118.803L113.626 23.9437L119.553 32.6182H113.694H113.69Z"
        fill="white"/>
      <path d="M64.7909 9.00393H59.5801V14.0952H64.7909V9.00393Z" fill="#15A6FF"/>
    </svg>
  )
}