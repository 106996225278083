import React from 'react'
import styled, { css } from 'styled-components';
import { countries } from 'mock/countries_data';
import { ValidationInfoType } from 'scribexUI/typings';
import { ChangeEvent, InputHTMLAttributes, useCallback, useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon, DangerIcon, InfoIcon } from 'scribexUI/icons';

interface InputTYpe extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string,
  value?: string,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  type?: string,
  label?: string,
  disabled?: boolean,
  validationInfo?: ValidationInfoType;
  isValid?: boolean;
  $isValid?: boolean;
  line?: boolean;
  $line?: boolean;
  inpSize?: 'sm' | 'lg' | 'fill',
  $inpSize?: 'sm' | 'lg' | 'fill',
  withIcon?: boolean
}


const sizes: Record<any, any> = {
  sm: {
    padding: '7px 16px',
    fontSize: '14px',
    lineHeight: '20px'
  },
  lg: {
    padding: '11px 16px',
    fontSize: '16px',
    lineHeight: '24px'
  },
  fill: {
    padding: '13px 0',
    fontSize: '14px',
    lineHeight: '20px'
  },
};

const width: Record<any, any> = {
  sm: {
    maxWidth: '343px',
  },
  lg: {
    maxWidth: '450px',
  },
  fill: {
    maxWidth: '100%',
  }
};

const StyledInputContainer = styled.div<InputTYpe>`
  position: relative;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  ${(props) => width[props.$inpSize as string]};
`;
const StyledSelectBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  background-color: transparent;
  width: 100%;
  z-index: 999;
`;

const StyledInput = styled.input<InputTYpe>`
  border: none;
  font-family: DMSans-Medium, sans-serif;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  color: #140D0B;

  &:focus {
    border: none;
    color: #331DAD;

    &::placeholder {
      color: #331DAD;
    }
  }

  &:focus + & {
    background-color: red;
    color: red;
    gap: 10px;
  }

  &:focus-visible {
    border: none;
    outline: none;
  }

  &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    color: #140D0B;
  }

  ${(props) =>
          props.disabled && css<InputTYpe>`
            background-color: #ffffff;
            cursor: not-allowed;
            color: rgba(20, 13, 11, .5);

            &::placeholder {
              color: rgba(20, 13, 11, .5);
            }
          `
  }
`;

const StyledInputLabel = styled.label<InputTYpe>`
  padding: 0;
  margin: 0;
  font-family: DMSans-SemiBold, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  ${(props) =>
          props.disabled && css<InputTYpe>`
            color: rgba(20, 13, 11, .5);
          `
  }
`;

const StyledMessageWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 6px;
  width: 100%;
`;

const StyledValidationIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
`;

const StyledMessage = styled.span<InputTYpe>`
  color: #68686B;
  font-family: DMSans-Regular, sans-serif;
  font-size: 12px;
  line-height: 16px;

  ${(props) => {
    if (typeof props.$isValid !== "undefined") {
      return props.$isValid ? css<InputTYpe>`
        color: #68686B;
      ` : css<InputTYpe>`
        color: #F5222D;
      `
    }
  }
  }
`;

const StyledInputWrapper = styled.div<InputTYpe>`
  border-radius: 6px;
  background-color: #FFFFFF;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  height: 46px;
  box-sizing: border-box;
  width: 100%;

  ${(props) => sizes[props.$inpSize as string]}
  ${(props) =>
          props.$line ? css<InputTYpe>`
            border-bottom: 1px solid #140D0B;
            border-radius: 0;
          ` : css<InputTYpe>`
            border: 1px solid #140D0B;
          `
  }
  &:focus-within {
    ${(props) => {
      if (typeof props.$isValid !== "undefined") {
        return props.$isValid && css<InputTYpe>`border-color: #331DAD`;
      } else {
        return css<InputTYpe>`border-color: #331DAD`;
      }
    }
    }
  }

  ${(props) => {
    if (typeof props.$isValid !== "undefined") {
      return props.$isValid ? css<InputTYpe>`
        border-color: #140D0B;
      ` : css<InputTYpe>`
        border-color: #F5222D;
      `
    }
  }
  }

  ${(props) =>
          props.disabled && css<InputTYpe>`
            border-color: rgba(20, 13, 11, .5);
            color: rgba(20, 13, 11, .5);
            cursor: not-allowed;
          `
  }
`;

// =-=-----------

const CustomSelectWrapper = styled.div<InputTYpe>`
  display: flex;
  align-items: center;
  gap: 4px;
  ${(props) =>
          props.disabled && css<InputTYpe>`
            border-color: #68686B;
            color: #68686B;
            cursor: not-allowed;
          `
  };
`;

const StyledIconWrapper = styled.span<InputTYpe>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  cursor: pointer;
  ${(props) =>
          props.disabled && css<InputTYpe>`
            cursor: not-allowed;
          `
  };
`;

const SelectButton = styled.button<InputTYpe>`
  width: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0;
  ${(props) =>
          props.disabled && css<InputTYpe>`
            cursor: not-allowed;
          `
  };
`;

const SelectedValueText = styled.span<InputTYpe>`
  font-family: DMSans-Medium, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #140D0B;
  ${(props) =>
          props.disabled && css<InputTYpe>`
            color: rgba(20, 13, 11, .5);
          `
  }
`

type DropdownMenuType = {
  $inpSize: string
}

const DropdownMenu = styled.div<DropdownMenuType>`
  position: absolute;
  top: 100%;
  left: 0;
  width: auto;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  ${(props) => width[props.$inpSize as string]};
  overflow-x: auto;
  padding: 8px;
  max-height: 304px;
`;

const OptionItem = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 6px;
  border-bottom: 0.5px solid #E6E6E6;

  &:hover {
    background-color: rgba(248, 248, 248, 1);
  }
`;
const OptionItemSuffix = styled.span`
  color: #68686B;
  font-family: DMSans-Regular, sans-serif;
  font-size: 14px;
  line-height: 20px;
`;

const OptionItemText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #68686B;
  font-family: DMSans-Regular, sans-serif;
  font-size: 14px;
  line-height: 20px;
`;

const OptionItemIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;
const CustomSelect = ({onChange, disabled, inpSize = 'lg', isIconVisible}: Record<any, any>) => {
  const countriesData = (countries || []).filter((_country: any) => _country.idd?.root).sort((a: any, b: any) => a.name.common.localeCompare(b.name.common));
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<Record<string, any>>({
    "img": "https://flagcdn.com/w320/gb.png",
    "text": "+4",
    "suffix": "4"
  });

  const toggleOptions = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleSelect = (option: any) => {
    onChange && onChange(option);
    setValue(option);
    setIsOpen(false);
  };

  const onBackdropClick = useCallback(() => {
    setIsOpen(false);
  }, []);
  return (
    <>
      {isOpen && <StyledSelectBackdrop onClick={onBackdropClick}/>}
      <CustomSelectWrapper disabled={disabled}>
        <SelectButton onClick={toggleOptions} disabled={disabled} type={'button'}>
          {value && isIconVisible && (
            <img src={value.img} alt={value.label} style={{width: '18px', height: '18px', borderRadius:'50%', border: '1px solid #E6E6E6'}}/>
          )}
          <SelectedValueText className={'gaspar'} disabled={disabled}>
            {value ? value.text + "" + value.suffix : 'Select an option'}
          </SelectedValueText>
          <StyledIconWrapper disabled={disabled}>{isOpen ? <ArrowUpIcon/> : <ArrowDownIcon/>}</StyledIconWrapper>
        </SelectButton>
        {isOpen && (
          <DropdownMenu $inpSize={inpSize}>
            {countriesData.map((option: any, index: any) => {
              const suffix = option?.idd?.suffixes?.length === 1 ? option?.idd?.suffixes[0] : '';
              return <OptionItem key={index} onClick={() => {
                handleSelect({img: option.flags.png, text: option?.idd?.root, suffix})
              }}>
                <OptionItemIcon>
                  <img
                    src={option.flags.png}
                    alt={option.label}
                    style={{width: '20px', height: '20px', borderRadius: "50%", border: '1px solid #E6E6E6'}}/>
                </OptionItemIcon>
                <OptionItemSuffix>
                  {option?.idd?.root}
                  {suffix}
                </OptionItemSuffix>
                <OptionItemText>
                  {option?.name?.common}
                </OptionItemText>
              </OptionItem>
            })}
          </DropdownMenu>
        )}
      </CustomSelectWrapper>
    </>
  );
};


// -----
export const SXPhoneInput = React.forwardRef <HTMLInputElement, InputTYpe>((props, ref) => {
  const {
    placeholder,
    value = "",
    onChange,
    label,
    disabled,
    validationInfo = {},
    isValid,
    line,
    withIcon,
    inpSize = 'lg',
    ...additionalElements
  } = props;

  const uniqueId = Math.random().toString(16).slice(2) + Date.now();
  const [inpVal, setInpVal] = useState(value);
  const {error, info, infoIcon = <InfoIcon/>, errorIcon = <DangerIcon/>} = validationInfo;


  let message = info;
  let icon = message ? infoIcon : null;
  if (typeof props.isValid !== "undefined") {
    message = !isValid ? error : info;
    icon = !isValid ? errorIcon : infoIcon;
  }


  const isValidationMessageVisible = !disabled && message;
  return (
    <StyledInputContainer $inpSize={inpSize}>
      {label && <StyledInputLabel htmlFor={uniqueId} disabled={disabled}>
        {label}
      </StyledInputLabel>}
      <StyledInputWrapper
        disabled={disabled}
        $isValid={isValid}
        $line={line}
        $inpSize={inpSize}
      >
        <CustomSelect disabled={disabled} inpSize={inpSize} isIconVisible={withIcon}/>
        <StyledInput
          ref={ref}
          type={'text'}
          value={inpVal}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(event) => {
            event.target.value = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
            if (inpVal == event.target.value) {
              event.preventDefault();
              return;
            }
            setInpVal(event.target.value);
            onChange && onChange(event);
          }}
          {...additionalElements}
        />
      </StyledInputWrapper>
      {isValidationMessageVisible && <StyledMessageWrapper>
        <StyledValidationIcon>
          {!disabled && icon}
        </StyledValidationIcon>
        <StyledMessage $isValid={isValid}>
          {!disabled && message}
        </StyledMessage>
      </StyledMessageWrapper>}
    </StyledInputContainer>
  );
});
