import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import styles from './styles.module.scss';

type NavLinkData = {
  title: string
  href: string
  index: number
}

interface INavLink {
  data: NavLinkData
  isActive?: boolean
  setSelectedIndicator: (href: string) => void
  onLinkClick: (value:boolean) => void
}

export const NavLink: FC<INavLink> = React.memo(({data, setSelectedIndicator, onLinkClick}) => {
  const {title, href, index} = data;
  const item = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        delay: index * 0.3,
        duration: 2.5,
        type: 'spring',
      }
    }
  }
  return (
    <motion.div variants={item} className={styles.link} onMouseEnter={() => {
      setSelectedIndicator(href)
    }} custom={index}
      // variants={slide}
                initial="hidden" animate="show" exit="exit">
      <motion.div
        // variants={scale} animate={isActive ? "open" : "closed"}
        className={styles.indicator}></motion.div>
      <Link to={href} onClick={() => onLinkClick(false)} className={styles.link_text}>{title}</Link>
    </motion.div>
  )
})