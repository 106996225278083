export const CloseIcon = ({color = '#A9A9AA'}) => {
  return (

    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6139 6.0075L15.5631 5L11.3992 8.9925L7.23522 5L6.18445 6.0075L10.3484 10L6.18445 13.9925L7.23522 15L11.3992 11.0075L15.5631 15L16.6139 13.9925L12.4499 10L16.6139 6.0075Z"
        fill={color}/>
    </svg>

    // <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <g clipPath="url(#clip0_2073_25007)">
    //     <path
    //       d="M16.5406 4.22169L15.7781 3.45921C15.6975 3.37901 15.5884 3.33398 15.4747 3.33398C15.3611 3.33398 15.252 3.37901 15.1714 3.45921L10.3022 8.32836C10.2625 8.36828 10.2152 8.39994 10.1631 8.42154C10.1111 8.44315 10.0553 8.45427 9.9989 8.45427C9.94254 8.45427 9.88674 8.44315 9.83469 8.42154C9.78264 8.39994 9.73537 8.36828 9.69558 8.32836L4.8264 3.45921C4.74582 3.37901 4.63677 3.33398 4.52308 3.33398C4.40939 3.33398 4.30032 3.37901 4.21974 3.45921L3.45726 4.22169C3.37706 4.30227 3.33203 4.41135 3.33203 4.52503C3.33203 4.63872 3.37706 4.74777 3.45726 4.82835L8.32641 9.69754C8.36632 9.73733 8.39799 9.78459 8.41959 9.83664C8.4412 9.8887 8.45232 9.9445 8.45232 10.0009C8.45232 10.0572 8.4412 10.113 8.41959 10.1651C8.39799 10.2171 8.36632 10.2644 8.32641 10.3042L3.45726 15.1734C3.37706 15.2539 3.33203 15.363 3.33203 15.4767C3.33203 15.5904 3.37706 15.6994 3.45726 15.78L4.21974 16.5425C4.25953 16.5824 4.30682 16.6141 4.35887 16.6357C4.41092 16.6573 4.46672 16.6684 4.52308 16.6684C4.57944 16.6684 4.63524 16.6573 4.68729 16.6357C4.73934 16.6141 4.78661 16.5824 4.8264 16.5425L9.69558 11.6734C9.77616 11.5932 9.88521 11.5481 9.9989 11.5481C10.1126 11.5481 10.2217 11.5932 10.3022 11.6734L15.1714 16.5425C15.2112 16.5824 15.2585 16.6141 15.3105 16.6357C15.3626 16.6573 15.4184 16.6684 15.4747 16.6684C15.5311 16.6684 15.5869 16.6573 15.639 16.6357C15.691 16.6141 15.7383 16.5824 15.7781 16.5425L16.5406 15.78C16.5805 15.7402 16.6121 15.693 16.6338 15.6409C16.6554 15.5889 16.6665 15.5331 16.6665 15.4767C16.6665 15.4203 16.6554 15.3645 16.6338 15.3125C16.6121 15.2604 16.5805 15.2131 16.5406 15.1734L11.6714 10.3042C11.5912 10.2236 11.5462 10.1145 11.5462 10.0009C11.5462 9.88717 11.5912 9.77812 11.6714 9.69754L16.5406 4.82835C16.5805 4.78856 16.6121 4.7413 16.6338 4.68924C16.6554 4.63719 16.6665 4.58139 16.6665 4.52503C16.6665 4.46868 16.6554 4.41288 16.6338 4.36082C16.6121 4.30877 16.5805 4.26148 16.5406 4.22169Z"
    //       fill={color}/>
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_2073_25007">
    //       <rect width="20" height="20" fill="white"/>
    //     </clipPath>
    //   </defs>
    // </svg>
  )
};