import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { viewportSize } from 'utils/helpers';
import { Form } from 'antd';

const allCookieNames = ["performance", "functionality", "advertising", "third-party", "essential"];
export const useManageCookie = (onModalClose: () => void) => {
  const [, setCookie] = useCookies();
  const {width} = viewportSize();
  const [formRef] = Form.useForm();


  const onRejectAll = useCallback(() => {
    setCookie("essential", true, {path: '/'});
    onModalClose();
  }, [onModalClose, setCookie]);

  const onAllowAll = useCallback(() => {
    allCookieNames.forEach(_cookie => setCookie(_cookie, true, {path: '/'}));
    onModalClose();
  }, [onModalClose, setCookie]);

  const onOnlyNecessary = useCallback(() => {
    allCookieNames.forEach((_cookie: any) => {
      if (formRef.getFieldsValue()[_cookie]) {
        setCookie(_cookie, true, {path: '/'});
      }
    });
    onModalClose()
  }, [onModalClose, formRef, setCookie]);

  return {
    onRejectAll,
    onAllowAll,
    onOnlyNecessary,
    width,
    formRef
  }
}