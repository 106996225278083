import React from 'react';

export const MinusIcon = React.memo(() => {
  return (
    <svg width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4522 2.61719H1.54779C0.900447 2.61719 0.375916 2.09266 0.375916 1.44531C0.375916 0.797969 0.900447 0.273438 1.54779 0.273438H16.4522C17.0995 0.273438 17.624 0.797969 17.624 1.44531C17.624 2.09266 17.0995 2.61719 16.4522 2.61719Z"
        fill="#140D0B"/>
    </svg>
  )
})