import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { ButtonProps, ButtonType } from 'scribexUI/typings';

const sizes: Record<ButtonType["size"], any> = {
  lg: {
    fontFamily: 'DMSans-Medium, sans-serif',
    fontSize: '20px',
    fontStyle: 'normal',
    lineHeight: '26px',
  },
  md: {
    fontFamily: 'DMSans-Medium, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '24px',
  },
  sm: {
    fontFamily: 'DMSans-Medium, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '24px',
  },
  xs: {
    fontFamily: 'DMSans-Medium, sans-serif',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '20px',
  },
}

const buttonStyles = css<ButtonProps>`
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: transparent;
  padding: 0;
  white-space: nowrap;
  color: ${(props) => props.color ? props.color : props.theme.colors.primary};

  ${(props) => sizes[props.size as ButtonType["size"]]}
  ${(props) =>
          props.$underline &&
          css`
            text-decoration: underline;
            text-underline-offset: 4px;
            font-family: DMSans-Regular, sans-serif;
          `}
  ${(props) =>
          props.disabled &&
          css<ButtonProps>`
              //color: ${(props) => props.theme.colors.primaryDisabled};
            color: #68686B;
            cursor: not-allowed;
          `}
  &:hover:not([disabled]) {
    color: ${(props) => props.$hoverColor ? props.$hoverColor : '#331DAD'};

    .svg-icon {
      stroke: ${(props) => props.$hoverColor ? props.$hoverColor : '#331DAD'};
    }
  }
`;

const StyledButton = styled.button<ButtonProps>`
  ${buttonStyles}
`;

const StyledIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SXTextButton: FC<ButtonProps> = (props) => {
  const {
    size = 'md',
    underline,
    children,
    onClick,
    $right_icon,
    $left_icon,
    disabled,
    color,
    hoverColor,
    className
  } = props;
  return (
    <StyledButton
      className={className}
      color={color}
      $hoverColor={hoverColor}
      size={size}
      $underline={underline}
      onClick={onClick}
      $left_icon={$left_icon}
      $right_icon={$right_icon}
      disabled={disabled}
    >
      {$left_icon && <StyledIconWrapper>{$left_icon}</StyledIconWrapper>}
      {children}
      {$right_icon && <StyledIconWrapper>{$right_icon}</StyledIconWrapper>}
    </StyledButton>
  );
};
