import React, { FC } from 'react';
import { IService } from 'typings';
import { Col, Row } from 'componnets';
import { SXTypography } from 'scribexUI';
import { viewportSize } from 'utils/helpers';
import styles from './styles.module.scss';
import { SXCollapse } from '../collapse';

const {Text} = SXTypography;

export const Service: FC<IService> = React.memo((props) => {
  const {
    title,
    description,
    optionList = []
  } = props;
  const {width } = viewportSize();
  return (
    <Row justify={'space-between'} className={styles.service} wrap={width <= 900} gutter={[0, {xs:16, sm:24}]}>
      <Col className={styles.title_container}>
        <Text weight={'medium'} color={'#331DAD'} className={styles.title}>{title}</Text>
      </Col>
      <Col className={styles.option_section}>
        <Row gutter={[0, {sm:20, xs:16, xl:24}]}>
          <Col>
            <Text size={'lg'} color={'#68686B'} className={styles.description}>{description}</Text>
          </Col>
          <Col span={24} className={styles.options_container}>
            {optionList.map(_option => <SXCollapse key={_option.id} {..._option}/>)}
          </Col>
        </Row>
      </Col>
    </Row>
  )
})