import { useCallback } from 'react';
import { ROUTES } from 'constants/routes';
import { useNavigate } from 'react-router-dom';

export const useContacts = () => {
  const navigate = useNavigate();

  const onProjectFormClick = useCallback(() => {
    navigate(ROUTES.projectForm);
  }, [navigate]);

  return {
    onProjectFormClick
  }
}